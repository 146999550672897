@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.tabsContainer {
    height: map-get($marginInPixel, "px60");
    padding: map-get($margins, "px16") map-get($margins, "px16") 0 map-get($margins, "px16");
    border-top-left-radius: map-get($marginInPixel, "px8");
    border-top-right-radius: map-get($marginInPixel, "px8");
    border: map-get($marginInPixel, "px1") solid $borderGrey;
    border-bottom: none;
}
  
.tabPanel {
    margin-top: 0;
    height: calc(100vh - 170px);
    border: map-get($marginInPixel, "px1") solid $borderGrey;
    border-top: none;
    background-color: $white;
    padding: map-get($margins, "px16");
    border-bottom-left-radius: map-get($marginInPixel, "px8");
    border-bottom-right-radius: map-get($marginInPixel, "px8");
}
