@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.popupContainer .popupStyles {
  width: map-get($marginInPercentage, "per30");
  max-width: map-get($marginInPixel, "px432");
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px22") map-get($marginInPixel, "px24") map-get($marginInPixel, "px22");
}

.helpContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px16");
}

.topSection, .bottomSection {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px16");
  align-items: flex-start;
}

.bottomSection {
  margin-bottom: 8px;
}

.contentSection {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px4");
  align-items: flex-start;
  .link {
    color: $purple;
    text-decoration: none;
    cursor: pointer;
  }
}

.divider {
  margin: map-get($marginInPixel, "px10") 0 map-get($marginInPixel, "px10") 0;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
}

.contactContent {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px16");
}
    
.contactUsHeader {
  line-height: map-get($marginInPixel, "px20");
  size: map-get($marginInPixel, "px16");
  font-weight: map-get($weight, "normal");
  color: $pureBlack
}
    
.contacts {
  display:flex;
  flex-direction: row;
  align-items: center;
}
    
.contactIcon {
  height: map-get($marginInPixel, "px16");
  width: map-get($marginInPixel, "px16");
  margin: map-get($marginInPixel, "px3") map-get($marginInPixel, "px6") 0 0;
}

.contactLabel {
  color: $textLabel;
  font-weight: map-get($weight, "normal");
  line-height: map-get($marginInPixel, "px22");
}
