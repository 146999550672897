/* index css */
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "GE Sans Pro Sans";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GE Sans Pro Bold";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf")
    format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "GE Sans Pro BoldItalic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "GE Sans Pro Italic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf")
    format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "GE Sans Pro Light Italic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf")
    format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "GE Sans Pro Light";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GE Sans Pro Extra Light";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GE Sans Pro Extra Light Italic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf")
    format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "GE Sans Pro Black";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GE Sans Pro Black Italic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GE Sans Pro Semi Bold";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "GE Sans Pro Semi Bold Italic";
  src: url("../public/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "GE Sans Pro Sans", "GE Sans Pro Bold",
    "GE Sans Pro BoldItalic", "GE Sans Pro Italic",
    "GE Sans Pro Light Italic", "GE Sans Pro Light",
    "GE Sans Pro Extra Light", "GE Sans Pro Extra Light Italic",
    "GE Sans Pro Black", "GE Sans Pro Black Italic",
    "GE Sans Pro Semi Bold", "GE Sans Pro Semi Bold Italic",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F3F4;
}

div#root {
  height: 100vh;
}

code {
  font-family: "GE Inspira Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

::-webkit-scrollbar {
  height: 8px !important; 
  width: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #80878E;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}