@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.activityDataWrapper {
    display: flex;
    align-items: center;
}

.barsWrapper {
    display: flex;
    height: map-get($marginInPixel, "px4");
    margin-top: map-get($marginInPixel, "px2");
    width: map-get($marginInPixel, "px100");
}

.successCount {
    font-weight: map-get($weight, "bold");
    white-space: nowrap;
    margin-left: map-get($marginInPixel, "px6");
}
