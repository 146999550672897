@import "../../../utils/styles/margins.scss";

.fleet {
  opacity: 1; // This is intentional
}
.groupByCards > a > div {
  margin-bottom: map-get($marginInPixel, "px8");
}

.removeLinkStyles {
  text-decoration: none;
  color: inherit;
}

.popupStyles {
  width: map-get($marginInPixel, "px600");
}

.groupByAssetsTable {
  padding: 0 map-get($marginInPixel, "px16");
  height: calc(100vh - 312px);
  overflow-y: auto;
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
      .noPadding {
        padding-left: 8px;
      }
    }
  }
}

.popupStyle {
  z-index: 10;
  width: map-get($marginInPercentage, "per35");
  position: fixed;
}

.cardViewContainer {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  overflow: auto;
  padding: 8px 12px 8px 12px;
  max-height: calc(100vh - 312px);
  overflow-y: auto;
}

.cardViewContainer > div {
  width: calc(25% - 7px) !important;
}
.cardViewContainer > div > div {
  width: auto !important;
}
