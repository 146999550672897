@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.headerContainer {
  background: $headerBackground;
  display: inline-flex;
  height: map-get($marginInPixel, "px48");
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid $borderGrey;
}

.headerText {
  color: $label;
  height: map-get($marginInPixel, "px22");
  line-height: map-get($marginInPixel, "px22");
}

.leftAlign {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: map-get($marginInPixel, "px16");
  height: map-get($marginInPixel, "px32");
  margin: map-get($marginInPixel, "px8") 0 map-get($marginInPixel, "px8")
    map-get($marginInPixel, "px16");
  padding: 0;
}

.signInButton {
  display: flex;
  align-items: center;
  margin-right: map-get($marginInPixel, "px16");
}

.signInButton > button {
  width: map-get($marginInPixel, "px124");
}

.signInText {
  cursor: pointer;
  color: $label;
  margin-left: map-get($marginInPixel , "px4");
  font-size: 16px;
}

.rightAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: map-get($marginInPixel , "px4");
}

.helpIcon {
  height: map-get($marginInPixel , "px24");
  width: map-get($marginInPixel , "px24");
  margin-top: 0;
}

.helpIconButton {
  height: map-get($marginInPixel , "px40");
  width: map-get($marginInPixel , "px40");
}

.helpIcon :hover {
  background-color: $activeUserBackground;
}
