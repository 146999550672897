@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.rightStatisticsCard {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: map-get($marginInPercentage, "per42");
  height: map-get($marginInPixel, "px86");
  gap: map-get($marginInPixel, "px4");
}


.statisticsCard {
  background-color: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  width: map-get($marginInPercentage, "per24");
  height: map-get($marginInPixel, "px120");
  padding: map-get($marginInPixel, "px12") map-get($marginInPercentage, "per1o25");
  display: flex;
  justify-content: center;
  align-items: center;
  gap: map-get($marginInPercentage, "per8");
  border-radius: map-get($marginInPixel, "px8");
}

.statisticsData {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  white-space: nowrap;
  width: map-get($marginInPercentage, "per45");
  align-items: center;
}

.divider {
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  height: map-get($marginInPixel, "px86");
}

.expandedStatisticCard {
  background-color: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px1");
  width: map-get($marginInPercentage, "per33");
  height: map-get($marginInPixel, "px120");
  padding: map-get($marginInPixel, "px12") map-get($marginInPercentage, "per1o25");
  display: flex;
  justify-content: center;
  align-items: center;
  gap: map-get($marginInPercentage, "per8");
}
