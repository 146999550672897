@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.addFleetButton {
  font-size: 0.65rem;
  margin-right: map-get($marginInPercentage, "per1");
  text-transform: none;
}

.assetsCountText {
  width: auto;
  max-width: map-get($marginInPercentage, "per26");
  margin: auto map-get($marginInPercentage, "per1");
  text-align: center;
  line-height: 18px;
}

.dropdown {
  background-color: $background;
  border: map-get($marginInPixel, "px1") solid map-get($input, "border");
  height: map-get($marginInPixel, "px34");
  outline: none;
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px12");
  width: map-get($marginInPercentage, "per20");
}

.headSection {
  height: map-get($marginInPixel, "px66");
  padding: map-get($margins, "px16");
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.fleets {
  height: calc(100vh - 320px);
  overflow-y: auto;
  padding: 0 map-get($marginInPixel, "px16");
  scroll-behavior: smooth;
}

.fleetsSummary {
  background-color: $white;
  height: calc(100vh - 238px);
  margin-top: map-get($marginInPixel, "px16");
  position: relative;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
}

.iconButton, .toggleButtonList {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px6");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
}

.iconButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.iconButton:active, .toggleButtonList:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.toggleButtonList:hover {
  background: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px8");
}
.toggleButtonCard {
  background: $toggleBackground;
  padding: map-get($marginInPixel, "px6");
  border-radius: map-get($marginInPixel, "px8");
  border: none;
  cursor: pointer;
  outline: none;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.leftSection {
  display: flex;
  width: map-get($marginInPercentage, "per45");
  justify-content: flex-start;
}

.rightSection {
  width: map-get($marginInPercentage, "per55");
  text-align: end;
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px6");
  align-items: center;
}

.searchInput {
  background-color: $border;
  border: map-get($marginInPixel, "px1") solid map-get($input, "border");
  height: map-get($marginInPixel, "px34");
  outline: none;
  padding: map-get($marginInPixel, "px12") map-get($marginInPixel, "px6");
  width: map-get($marginInPercentage, "per45");
}

.searchBar {
  width: map-get($marginInPercentage, "per30");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
}

.staticCards {
  display: flex;
  justify-content: space-between;
  height: map-get($marginInPixel, "px120");
}

.confirmationPopUp {
  width: map-get($marginInPercentage, "per42");
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
  margin-left: map-get($marginInPercentage, "per1");
}

.iconStyles {
  margin-top: 0;
}

.filterError {
  margin-top: map-get($marginInPixel, "px50");
}

.filterMessageError {
  margin-top: map-get($marginInPixel, "px70");
}

.popupStyle {
  z-index: 10;
  width: map-get($marginInPercentage, "per35");
  position: fixed;
}
.contentLoading {
  height: map-get($marginInPixel, "px264");
}
.popupStyles {
  width: map-get($marginInPixel, "px600");
}