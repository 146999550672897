@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.overlay {
  background: $createUpdateOverlayBackground;
  bottom: 0;
  left: 0;
  opacity: 0.72;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.infoContainer {
  font-size: map-get($size, "px14");
  line-height: map-get($marginInPixel, "px20");
  font-weight: map-get($weight, "normal");
  color: $createUpdateOverlayInfoContainer;
  display: block;
  white-space: pre;
  margin-left: 2px;
}

.assetName, .addNote {
  color: $textLabel;
  margin-bottom: map-get($marginInPixel, "px8");
}

.addNote {
  margin-top: map-get($marginInPixel, "px18");
}

.instructionText {
  display: block;
  color: $textLabel;
  font-size: map-get($marginInPixel, "px14");
  font-weight: normal;
  line-height: map-get($marginInPixel, "px20");
  margin-top: map-get($marginInPixel, "px4");
}

.textFieldContainer {
  width: map-get($marginInPercentage, "per100");
}

.textField {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px82");
  resize: none;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px4");
  outline: $textFieldOutline;
  color: $noResultText;
  background-color: $filterItemBackground;
}
