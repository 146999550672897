@import "../../../../../utils/styles/margins.scss";
@import "../../../../../utils/styles/colors.scss";
@import "../../../../../utils/styles/fonts.scss";

.card {
  width: map-get($marginInPercentage, "per100");
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px4");
  height: map-get($marginInPixel, "px108");
  position: relative;
  z-index: 10;
  border-bottom: map-get($marginInPixel, "px1") solid #d1d2d4;
}

.cardHeader {
  display: flex;
  font-weight: map-get($weight, "bolder");
  font-size: map-get($marginInPixel, "px16");
  line-height: map-get($marginInPixel, "px22");
  gap: map-get($marginInPixel, "px6");
  .cardTitle {
    padding-top: map-get($marginInPixel, "px4");
  }
}

.cardContent {
  color: #313235;
  display: flex;
  flex-direction: column;
  margin-left: map-get($marginInPixel, "px44");
  gap: map-get($marginInPixel, "px8");
  margin-top: map-get($marginInPixel, "px8");
  font-size: map-get($marginInPixel, "px14");
  .deployInfo {
    font-size: map-get($marginInPixel, "px12");
    display: flex;
  }
}

.iconStyles {
  height: map-get($marginInPixel, "px16");
  margin-top: 0;
}

.toolTipStyle {
  height: map-get($marginInPixel, "px16");
  margin-top: map-get($marginInPixel, "px14");
  width: map-get($marginInPixel, "px16");
}

.actionButtons {
  margin-left: auto;
  display: inline-flex;
  gap: map-get($marginInPixel, "px4");
}

.actionButtons button {
  border-radius: map-get($marginInPixel, "px8");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  padding: map-get($marginInPixel, "px8");
}

.actionButtons button:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.actionButtons button:active {
  background-color: map-get($buttons, "contentPrimaryfocus");
}

.mutedActions {
  opacity: 0.5;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: map-get($marginInPixel, "px120");
  margin: 0 map-get($marginInPixel, "px4");
}

.userName {
  display: flex;
}
