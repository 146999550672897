@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.addFleetButton {
  font-size: 0.65rem;
  margin-right: map-get($marginInPercentage, "per1");
  text-transform: none;
}

.assetsCountText,
.searchCountText {
  line-height: map-get($marginInPixel, "px18");
  text-align: center;
  width: auto;
  max-width: map-get($marginInPercentage, "per30");
  margin: auto map-get($marginInPercentage, "per1");
}

.selectedCountText {
  width: auto;
  max-width: map-get($marginInPercentage, "per26");
  margin: auto map-get($marginInPercentage, "per1");
  text-align: right;
}

.confirmationPopUp {
  width: map-get($marginInPercentage, "per42");
}

.dropdown {
  background-color: $background;
  border: map-get($marginInPixel, "px1") solid map-get($input, "border");
  height: map-get($marginInPixel, "px34");
  outline: none;
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px12");
  width: map-get($marginInPercentage, "per27o8");
}

.filtersSection {
  height: map-get($marginInPixel, "px66");
  padding: map-get($margins, "px16");
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.fleets {
  height: calc(100vh - 320px);
  overflow-y: auto;
  padding: 0 map-get($marginInPixel, "px16");
  scroll-behavior: smooth;
}

.fleetsSummary {
  background-color: $white;
  height: calc(100vh - 238px);
  margin-top: map-get($marginInPixel, "px16");
  position: relative;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
}

.iconButton,
.toggleButtonList {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  padding: map-get($marginInPixel, "px6");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
}

.iconButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.iconButton:active,
.toggleButtonList:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.toggleButtonList:hover {
  background: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px8");
}
.toggleButtonCard {
  background: $toggleBackground;
  padding: map-get($marginInPixel, "px6");
  border-radius: map-get($marginInPixel, "px8");
  border: none;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.pageContentLeft {
  display: flex;
  width: map-get($marginInPercentage, "per45");
  justify-content: flex-start;
}

.pageContentRight {
  display: flex;
  width: map-get($marginInPercentage, "per55");
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px6");
}

.searchInput {
  background-color: $border;
  border: map-get($marginInPixel, "px1") solid map-get($input, "border");
  height: map-get($marginInPixel, "px34");
  outline: none;
  padding: map-get($marginInPixel, "px12") map-get($marginInPixel, "px6");
  width: map-get($marginInPercentage, "per45");
}

.searchField {
  width: map-get($marginInPercentage, "per30");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
}

.staticCards {
  display: flex;
  height: map-get($marginInPixel, "px120");
  justify-content: space-between;
}

.groupByAssetsTable {
  padding: 0 map-get($marginInPixel, "px16");
  height: calc(100vh - 312px);
  overflow-y: auto;
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
      .noPadding {
        padding-left: 8px;
      }
    }
  }
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
  margin-left: map-get($marginInPercentage, "per1");
}

.popupStyles {
  width: map-get($marginInPixel, "px600");
}

.iconStyles {
  margin-top: 0;
}

.groupByLoader {
  height: calc(100vh - 305px);
}

.filterErrorPage {
  margin-top: map-get($marginInPixel, "px50");
  height: calc(100vh - 320px);
}

.popupStyle {
  z-index: 100;
  width: map-get($marginInPercentage, "per35");
  position: fixed;
}

.cardViewContainer {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  overflow: auto;
  padding: 8px 12px 8px 12px;
  max-height: calc(100vh - 312px);
  overflow-y: auto;
}

.cardViewContainer > div {
  width: calc(25% - 7px) !important;
}
.cardViewContainer > div > div {
  width: auto !important;
}
.contentLoading {
  height: map-get($marginInPixel, "px264");
}

.paginationLoaderContainer {
  position: relative;
}
