@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";
@import "../../../../utils/styles/fonts.scss";

.actionsCell {
  align-items: center;
  display: flex;
  gap: map-get($marginInPercentage, "per19");
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
}

.notesIcon{
  margin-left:  map-get($marginInPixel, "px12");
}

.buttonContainer {
  display: flex;
  gap: map-get($marginInPixel, "px6");
  .selectedItemCount {
    font-size: map-get($marginInPixel, "px14");
    line-height: map-get($marginInPixel, "px18");
    font-weight: normal;
    margin: map-get($marginInPixel, "px8");
  }
  .iconStyle {
    :disabled{
      opacity: 0.5;
    }
  }
}

.actionIcon {
  padding: map-get($marginInPixel, "px8");
  border-radius: map-get($marginInPixel, "px8");
  margin-top: 0;
}

.actionsCell button {
  padding: 0;
}

.actionIcon:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
}

.actionIcon:active {
  background-color: map-get($buttons, "contentPrimaryfocus");
}

.icon {
  margin-right: map-get($marginInPercentage, "per8");
}

.itemText {
  margin-right: map-get($marginInPixel, "px8");
}

:global(.FleetMenu) {
  .menuButton {
    padding: map-get($marginInPixel, "px8");
  }
  .active {
    border-radius: map-get($marginInPixel, "px8");
    background-color: $fleetMenuActive;
    padding: map-get($marginInPixel, "px8");
  }
  .menuItem:hover {
    background-color: $selectedBackground;
  }
  .menuButton:hover {
    border-radius: map-get($marginInPixel, "px8");
    background-color: $borderGrey;
    padding: map-get($marginInPixel, "px8");
  }
}

.noConfigFileContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .heading {
    font-weight: map-get($weight, "bolder");
    font-size: map-get($size, "px20");
    line-height: map-get($marginInPixel, "px26");
  }
  .message {
    color: $textValue;
  }
}
