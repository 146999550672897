@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.container {
  background: #ffffff;
  height: calc(100vh - 48px);
}
.topContainer {
  height: map-get($marginInPixel, "px66");
  width: map-get($marginInPercentage, "per45o3");
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px24");
}
.iconLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: map-get($marginInPixel, "px8");
  font-size: map-get($size, "px22");
}
.bottomContainer {
  height: calc(100vh - 114px);
  padding: 0 map-get($marginInPixel, "px24") map-get($marginInPixel, "px24")
    map-get($marginInPixel, "px24");
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottomLeftContainer {
  width: map-get($marginInPercentage, "per43o6");
  :global {
    & .searchContainer {
      display: flex;
      margin-bottom: map-get($marginInPixel, "px8");
      justify-content: space-between;
      align-items: center;
      & .selectionText {
        width: 75%;
      }
    }
  }
}
.accordions {
  overflow-y: auto;
  height: calc(100vh - 186px);
}

.bottomRightContainer {
  width: map-get($marginInPercentage, "per54o7");
  border: map-get($marginInPixel, "px1") solid #d7d8db;
  border-radius: map-get($marginInPixel, "px3");
  margin-left: map-get($marginInPixel, "px24");
}

.usersAccordion {
  margin-top: map-get($marginInPixel, "px16");
}

.labelStyles {
  font-weight: map-get($weight, "bolder");
  margin-right: map-get($marginInPixel, "px12");
}
.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.descriptionSection {
  margin-top: map-get($marginInPixel, "px8");
}
.detailsSection {
  margin-top: map-get($marginInPixel, "px8");
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: map-get($marginInPixel, "px24");
}
.innerDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}
.tabDetails {
  margin-top: map-get($marginInPixel, "px16");
  :global {
    & .tableContainer {
      height: calc(100vh - 400px);
    }
  }
}

.rightUpperContainer {
  padding: map-get($marginInPixel, "px24");
}

.rightLowerContainer {
  padding: 0 map-get($marginInPixel, "px24") map-get($marginInPixel, "px24")
    map-get($marginInPixel, "px24");
}

.chipStyles {
  border-radius: map-get($marginInPixel, "px6");
}

.title {
  display: flex;
  gap: map-get($marginInPixel, "px10");
}

.manageAccessButton:not(.btn-disabled) {
  display: flex;
  justify-content: flex-end;
  margin-bottom: map-get($margins, "px16");
}

.sitesTable {
  height: calc(100vh - 340px);
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: calc(100vh - 340px);
    }
  }
}

.membersTable {
  height: calc(100vh - 290px);
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: calc(100vh - 290px);
    }
  }
}

.userGroupTable {
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      min-height: map-get($marginInPixel, "px200");
    }
  }
}

.searchErrorContainer {
  padding: map-get($marginInPixel, "px20") 0;
}

.subRowExpandIconStyles {
  min-height: fit-content;
}

.manageAccessIcon {
  filter: brightness(0) invert(1);
}

.nestedTableContainer {
  padding-left: map-get($marginInPixel, "px50");
}

.loader,
.error {
  height: calc(100vh - 160px);
}
.manageButton:not(.btn-disabled) {
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px8");
}

.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}
