@import "../../../utils/styles/margins.scss";

.removeLinkStyles {
  text-decoration: none;
  color: inherit;
}

.groupByCards {
  padding: 0 map-get($marginInPixel, "px16");
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.groupByCards > a > div {
  margin-bottom: map-get($marginInPixel, "px8");
}
