@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.overlay {
  background: $createUpdateOverlayBackground;
  bottom: 0;
  left: 0;
  opacity: 0.72;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
.inputContainer {
  height: map-get($marginInPixel, "px64");
}
.infoContainer {
  font-size: map-get($size, "px14");
  line-height: map-get($marginInPixel, "px20");
  font-weight: map-get($weight, "normal");
  color: $createUpdateOverlayInfoContainer;
  white-space: pre;
  display: block;
  margin-left: map-get($marginInPixel, "px2");
}

.errorInfo {
  display: flex;
  color: $createUpdateOverlayErrorInfo;
  margin-top: map-get($marginInPixel, "px4");
}

.icon {
  height: map-get($marginInPixel, "px16");
  margin-right: map-get($marginInPixel, "px4");
}
