@import "./checkbox.scss";
@import "./colors.scss";
@import "./margins.scss";

.removeLinkStyles {
  text-decoration: none;
  color: inherit;
  z-index: 1;
}

.pointerEventsNone {
  pointer-events: none;
}

.visibilityHidden {
  visibility: collapse;
}

.noPadding {
  padding: 0;
}

.opacityo5 {
  opacity: 0.5;
}

.opacityForText {
  color: $opacityForText;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.tooltipTitle {
  white-space: pre-line;
  opacity: 1;
  z-index: 9999;
}

.textArea {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px82");
  resize: none;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px4");
  outline: $textFieldOutline;
  color: $noResultText;
  background-color: $filterItemBackground;
}

.textArea:hover {
  background-color: $inputFieldHover;
}

.textArea:focus {
  border: map-get($marginInPixel, "px2") solid $secondary;
}

.inputLable {
  color: $textLabel;
}
.inputInstructions {
  display: block;
  color: $textLabel;
  font-size: map-get($marginInPixel, "px14");
  font-weight: normal;
  line-height: map-get($marginInPixel, "px20");
}

.justify-right {
  justify-content: right;
}

.justify-center {
  justify-content: center;
}

.btn-disabled {
  box-shadow: none !important;
  background-color: #ececf0 !important;
}
