@import "../../../utils/styles/margins.scss";

.topSection {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}


:global(.assetMutedBar) .assetFaultsTable {
    height: calc(100vh - 404px);
}


.assetFaultsTable {
  height: calc(100vh - 384px);
  overflow-y: auto;
  margin-top: map-get($marginInPixel, "px20");
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: inherit;
    }
    & .tableLoader {
      height: calc(100vh - 394px);
    }
  }
}

.loader {
  height: calc(100vh - 329px);
}
