@import "../utils/styles/margins.scss";

.breadcrumbs {
  grid-column: span 12;
}

.content {
  grid-column: span 11;
  height: calc(100vh - 102px);
  margin: map-get($marginInPixel, "px16") map-get($marginInPixel, "px16") 0 0;
}

.header {
  grid-column: span 12;
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.page {
  column-gap: 16px;
  display: grid;
  grid-template-columns: auto repeat(11,1fr);
  grid-template-rows: 48px 32px calc(100vh - 82px);
}

.sideBar {
  grid-column: span 1;
}

:global(.hiddenSideNav) .content {
  grid-column: span 12;
  margin: 0;
}
