@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.confirmContent {
  display: block;
  line-height: map-get($marginInPixel, "px22");
  margin-bottom: map-get($marginInPixel, "px24");
  margin-top: map-get($marginInPixel, "px24");
}
.confirmText {
  color: $red;
  line-height: map-get($marginInPixel, "px22");
}
.warningIcon {
  margin-right: map-get($marginInPixel, "px5");
}
.warningSection {
  display: flex;
  margin-bottom: map-get($marginInPixel, "px24");
}
.root {
  width: 42%;
}
