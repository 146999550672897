@import "../../../utils/styles/colors.scss";

.tabPanel {
  margin-top: 12px;
  border: 1px solid $borderGrey;
  border-radius: 8px;
  background-color: $white;
  padding: 1rem;
}

.tabsContainer {
  height: 60px;
  padding: 16px 16px 0 16px;
}

.root{
  margin: 0 -16px;
  background-color: $white;
}

.configTab {
  margin-top: 12px;
}
