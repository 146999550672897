@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.actionsCell {
  align-items: center;
  display: flex;
  gap: map-get($marginInPercentage, "per8");
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
}
.disableAction {
  opacity: 0.5;
}
.actionsCell button{
  padding: 8px;
}

.actionsCell button:hover{
  background-color: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.actionsCell button:active {
  background-color: map-get($buttons, "contentPrimaryfocus");
}

.icon {
  margin-top: 0;
}

.mutedActions {
  opacity: 0.5;
}

.emptyButtonDiv {
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
}
