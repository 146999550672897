@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.popUpContainer {
  max-height: map-get($marginInPixel, "px548");
  width: map-get($marginInPercentage, "per30");
}

.contentContainer {
  max-height: map-get($marginInPixel, "px408");
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px12");
}

.chatBoxContainer {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px12");
  max-height: map-get($marginInPixel, "px300");
  overflow-y: auto;
  overflow-x: hidden;
  .chatReceivedContainer {
    display: flex;
    flex-direction: row;
    gap: map-get($marginInPixel, "px12");
    margin-right: map-get($marginInPixel, "px4");
    .userNameInitials {
      width: map-get($marginInPixel, "px32");
      height: map-get($marginInPixel, "px32");
      background: $textValue;
      color: $badgeText;
      border-radius: map-get($marginInPixel, "px20");
      display: flex;
      justify-content: center;
      align-items: center;
      padding: map-get($marginInPixel, "px16");
    }
    .userDetails {
      display: flex;
      flex-direction: column;
      width: map-get($marginInPercentage, "per86o5");
      gap: map-get($marginInPixel, "px2");
      .usernameTimestampData {
        display: flex;
        flex-direction: row;
        gap: map-get($marginInPixel, "px12");
        .username {
          max-width: map-get($marginInPercentage, "per40");
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .note {
        background-color: $noteReceiveBg;
        border-radius: map-get($marginInPixel, "px8");
        padding: map-get($marginInPixel, "px8") map-get($marginInPixel, "px12");
        width: fit-content;
        align-self: flex-start;
        color: $textValue;
        white-space: pre-line;
        overflow: hidden;
        text-wrap: wrap;
        overflow-wrap: anywhere;
      }
    }
  }
  .chatSentContainer {
    display: flex;
    flex-direction: column;
    gap: map-get($marginInPixel, "px2");
    margin-right: map-get($marginInPixel, "px4");
    .currentDateTime {
      display: flex;
      justify-content: flex-end;
    }
    .note {
      display: flex;
      justify-content: flex-end;
      background-color: $noteSendBg;
      border-radius: map-get($marginInPixel, "px8");
      padding: map-get($marginInPixel, "px8") map-get($marginInPixel, "px12");
      width: fit-content;
      align-self: flex-end;
      color: $textValue;
      white-space: pre-line;
      overflow: hidden;
      text-wrap: wrap;
      overflow-wrap: anywhere;
      max-width: map-get($marginInPercentage, "per86o5");
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px4");
  max-height: map-get($marginInPixel, "px96");
  .inputText {
    background-color: $filterItemBackground;
    padding: map-get($marginInPixel, "px10") map-get($marginInPixel, "px12");
    width: 100%;
    height: map-get($marginInPixel, "px60");
    border-radius: map-get($marginInPixel, "px4");
    border: map-get($marginInPixel, "px1") solid darkgrey;
    font-style: italic;
    &:focus-visible {
      outline: none;
    }
  }
  .labelButtonContainer {
    height: map-get($marginInPixel, "px32");
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .labelText {
      color: $textLabel;
      display: flex;
      align-items: center;
      font-size: map-get($size, "px14");
    }
    .sendButton {
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
