@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/colors.scss";

.staticCards {
  display: flex;
  height: map-get($marginInPixel, "px120");
  justify-content: space-between;
}

.subFleetsSummary {
  background-color: $white;
  height: calc(100vh - 238px);
  margin-top: map-get($marginInPixel, "px16");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  position: relative;
  border-radius: map-get($marginInPixel, "px8");
}

.assetSummary {
  padding: map-get($marginInPixel, "px16");
}

.headSection {
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.leftSection {
  display: flex;
  width: map-get($marginInPercentage, "per45");
  text-align: start;
}

.rightSection {
  width: map-get($marginInPercentage, "per55");
  text-align: end;
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px6");
  align-items: center;
}

.iconButton,
.toggleButtonList {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px6");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.iconButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.iconButton:active,
.toggleButtonList:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.toggleButtonList:hover {
  background: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px8");
}

.toggleButtonCard {
  background: $toggleBackground;
  padding: map-get($marginInPixel, "px6");
  border-radius: map-get($marginInPixel, "px8");
  border: none;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.iconStyles {
  margin-top: 0;
}

.searchBar {
  width: map-get($marginInPercentage, "per30");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
}

.assetContainerCardView {
  overflow-y: auto;
}

.assetContainer,
.assetContainerCardView {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 326px);
  :global {
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
      .noPadding {
        padding-left: 8px;
      }
    }
    .internalSelectAll {
      margin-left: 8px;
    }
  }
}

.assetCard {
  width: calc(25% - 7px);
}

.assetCardContainer {
  display: inline-flex;
  flex-flow: row wrap;
  gap: map-get($marginInPixel, "px8");
  width: map-get($marginInPercentage, "per100");
  overflow: auto;
}

.popupStyles {
  width: map-get($marginInPixel, "px600");
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
  margin-left: map-get($marginInPercentage, "per1");
}

.assetsCountText {
  width: auto;
  max-width: map-get($marginInPercentage, "per26");
  margin: auto map-get($marginInPercentage, "per1");
  text-align: center;
  line-height: 18px;
}

.confirmationPopUp {
  width: map-get($marginInPercentage, "per42");
}
.contentLoading {
  height: map-get($marginInPixel, "px264");
}
