@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/mixins.scss";

.icons {
  height: map-get($marginInPixel, "px12");
  width: map-get($marginInPixel, "px12");
}

.stepsIcon {
  margin-left: map-get($marginInPixel, "px6");
}

.gridContainer {
  display: flex;
  font-size: map-get($size, "px14");
  width: fit-content;
}

.cardRootIndicator {
  align-items: center;
  display: flex;
  padding: map-get($marginInPixel, "px4");
  justify-content: center;
  border-radius: map-get($marginInPixel, "px4");
}

.gridRootIndicator {
  @include swStatusOrConfigStatusChip;
}

.gridContainer button {
  margin-left: map-get($marginInPixel, "px6");
  padding: 0;
}

.actionIcon {
  margin-top: 0;
}