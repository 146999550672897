@import "../../../../../../utils/styles/margins.scss";
@import "../../../../../../utils/styles/colors.scss";
@import "../../../../../../utils/styles/fonts.scss";

.assetsTable {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 312px);
  overflow-y: auto;
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
    & .tableLoader {
      height: calc(100vh - 364px);
    }
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
      .noPadding {
        padding-left: map-get($marginInPixel, "px8");
      }
    }
    & .customTableContainer {
      .noPadding {
        width: map-get($marginInPixel, "px16") !important;
      }
    }
  }
}

.headerContainer {
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.rightPanel {
  display: flex;
  gap: map-get($marginInPixel, "px10");
  align-items: center;
  position: absolute;
  top: map-get($marginInPixel, "px20");
  right: 0;
}

.rightPanel .rejectButton {
  width: map-get($marginInPixel, "px82");
}

.rightPanel .approveButton {
  width: map-get($marginInPixel, "px96");
}

.searchResultsText {
  align-self: center;
}
