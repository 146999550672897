@import "../../../utils/styles/colors.scss";
@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/constants.scss";

.menuItem {
  align-items: center;
  color: map-get($sideNavigation, "title");
  display: flex;
  height: $sideNavigationMenuItemHeight;
  width: $sideNavigationMenuItemWidth;
  list-style-type: none;
  gap: map-get($marginInPixel, "px16");
  position: relative;
}

.menuItem:not(.active):not(.disabled):hover {
  background-color: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px8");
}

.count {
  position: absolute;
  top: 0;
  right: map-get($marginInPixel, "px2");
}

.expandedCount {
  right: map-get($marginInPixel, "px146");
}

.expandedMenu {
  width: $sideNavigationMenuItemExpandedWidth;
}

.active {
  background-color: map-get($sideNavigation, "menuItemBackground");
  border-radius: map-get($marginInPixel, "px8");
  border: 1px solid map-get($sideNavigation, "menuItemActiveBorder");
  & .menuItemTitle {
    font-weight: map-get($weight, "bolder");
  }
}

.disabled {
  color: map-get($sideNavigation, "disabledText");
  cursor: unset;
}

.disabled a {
  cursor: unset;
}

.menuItemIcon {
  height: map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px24");
}

.menuItemLink {
  align-items: center;
  color: inherit;
  display: flex;
  height: map-get($marginInPercentage, "per100");
  justify-content: flex-start;
  text-decoration: none;
  width: map-get($marginInPercentage, "per100");
  gap: map-get($marginInPixel, "px8");
  padding: map-get($marginInPixel, "px12");
}

.menuItemTitle {
  overflow: hidden;
  white-space: nowrap;
  line-height: map-get($marginInPixel, "px24");
}
