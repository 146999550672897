@import "../../../../../utils/styles/margins.scss";

.headerContainer {
  display: flex;
  margin: map-get($marginInPixel, "px16") 0;
  width: map-get($marginInPercentage, "per100");
}

.dropDownContainer {
  display: flex;
  justify-content: flex-start;
  width: map-get($marginInPercentage, "per80");
  gap: map-get($marginInPixel, "px10");
}

.rightPanel {
  display: flex;
  justify-content: flex-end;
  width: map-get($marginInPercentage, "per20");
  gap: map-get($marginInPixel, "px10");
}

.inputWidth {
  width: map-get($marginInPercentage, "per35");
  display: flex;
  gap: map-get($marginInPixel, "px12");
  align-items: center;
  max-width: map-get($marginInPixel, "px350");
}

.calendarContainer {
  width: map-get($marginInPercentage, "per32");
  max-width: map-get($marginInPixel, "px350");
}

.searchResultsText {
  align-self: center;
}

.dropDown {
  display: flex;
  gap: map-get($marginInPixel, "px10");
  width: map-get($marginInPercentage, "per28");
  max-width: map-get($marginInPixel, "px340")
}

.contentInfo {
  width: map-get($marginInPercentage, "per50");
}