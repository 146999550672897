@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/colors.scss";

.topHeading {
  display: flex;
  justify-content: space-between;
}

.assetSummaryContainer {
  background-color: $white;
  height: calc(100vh - 155px);
  margin-top: map-get($marginInPixel, "px16");
  position: relative;
  padding: map-get($marginInPixel, "px16");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
}

.filtersSection {
  display: flex;
  height: map-get($marginInPixel, "px34");
}

.pageContentRight {
  display: flex;
  margin-left: auto;
}

.pageContentRight > button {
  margin-right: map-get($marginInPercentage, "per4");
}

.pageContentLeft {
  display: flex;
  width: map-get($marginInPercentage, "per80");
  align-items: center;
  white-space: nowrap;
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
  margin-left: map-get($marginInPercentage, "per1");
}

.assetsTable {
  height: calc(100vh - 220px) !important;
  margin-top: map-get($margins, "px8");
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
  }
}

.pageContentRight .filterButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px5");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
  img {
    margin-top: 0;
  }
}

.pageContentRight .filterButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.pageContentRight .filterButton:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.deployButtonCont {
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px10");
}

.deployButtonCont button {
  width: map-get($marginInPixel, "px106");
}

.softwareHeader {
  display: flex;
  align-items: center;
  gap: map-get($marginInPixel, "px16");
  & .headingContainer {
    display: flex;
    gap: map-get($marginInPixel, "px8");
  }
  & .heading {
    font-size: map-get($size, "px24");
    color: $primary;
    max-width: map-get($marginInPixel, "px548");
  }
  & .subHeading {
    color: $textLabel;
  }
  & .subHeadingValue {
    color: $textValue;
    font-weight: map-get($weight, "bolder");
  }
  & .backButton {
    padding: map-get($marginInPixel, "px8");
    height: map-get($marginInPixel, "px32");
    width: map-get($marginInPixel, "px32");
  }
}

.deployIcon {
  margin-top: 0;
}

.contentLoading {
  height: map-get($marginInPixel, "px275");
}

.searchResultsText {
  align-self: center;
  text-align: right;
  margin-right: map-get($margins, "px5");
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
