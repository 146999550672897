@import "../../../../utils/styles/margins.scss";

.root {
  padding: 0 map-get($marginInPixel, "px12");
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 48px;
  width: 100%;
  :global {
    & div.children {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
