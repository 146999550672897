@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.accordionLayout {
    border: map-get($marginInPixel, "px1") solid $border;
    border-radius: map-get($marginInPixel, "px3");
    box-shadow: none;
    margin: 0;
}
  
.accordionDetailsStyles {
    border-radius: map-get($marginInPixel, "px3");
    :global {
        & .customTable .selectAllWithColorBar {
            margin-left: map-get($margins, "px8");
        }
    }
}

.accordionDetailsStyles:global(.HasHorizontalScroll) > div {
    overflow: auto;
}

.accordionDetailsStyles > div {
    overflow: hidden;
}

.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accordionLabel {
    display: flex;
    align-items: center;
}

.labelStyles {
    color: map-get($buttons, "chipLabel");
    font-weight: map-get($weight, "bolder");
    margin-right: map-get($marginInPixel, "px12");
}

  
.icon {
    height: map-get($marginInPixel, "px14");
    width: map-get($marginInPixel, "px16");
    margin-right: map-get($marginInPixel, "px12");
}