@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.fleetSection {
  height: map-get($marginInPixel, "px294");
  overflow-y: auto;
}
.fleetItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fleetItemSelected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subFleet {
  display: flex;
  flex-direction: column;
  border-bottom: map-get($marginInPixel, "px1") solid $borderColor;
}

.selectedSubFleet {
  display: flex;
  flex-direction: column;
  background-color: $lightGray;
  border: none;
}

.fleetContentClose {
  border-bottom: map-get($marginInPixel, "px1") solid $borderGrey;
  display: flex;
  margin: auto;
  padding-bottom: map-get($marginInPixel, "px12");
  padding-top: map-get($marginInPixel, "px12");
}

.fleetContentOpen {
  border-bottom: map-get($marginInPixel, "px1") solid $borderGrey;
  display: flex;
  margin: map-get($marginInPixel, "px1");
  padding-bottom: map-get($marginInPixel, "px12");
  padding-top: map-get($marginInPixel, "px12");
  background-color: $subTableHeaderBackground;
}

.selectedFleetContentClosed {
  border: map-get($marginInPixel, "px1") solid $tableSelectedBorder;
  display: block;
  border-radius: map-get($marginInPixel, "px4");
  background-color: $lightGray;
  margin-right: map-get($marginInPixel, "px2");
}

.selectedFleetContentOpen {
  border: map-get($marginInPixel, "px1") solid $tableSelectedBorder;
  display: block;
  border-radius: map-get($marginInPixel, "px4");
  margin-right: map-get($marginInPixel, "px2");
}

.fleetRadio {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: map-get($marginInPercentage, "per3") !important;
  margin-left: map-get($marginInPercentage, "per3") !important;
}

.fleetsIcon {
  width: map-get($marginInPixel, "px14");
}

.fleetsIconButton {
  margin-right: map-get($marginInPercentage, "per5") !important;
  margin-left: map-get($marginInPercentage, "per3") !important;
  padding: 0 !important;
}

.subFleetLabel {
  // border-bottom: map-get($marginInPixel, "px1") solid $borderColor;
  margin-left: map-get($marginInPercentage, "per6");
  padding-bottom: map-get($marginInPixel, "px15");
  padding-top: map-get($marginInPixel, "px15");
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subFleetRadio {
  cursor: pointer;
  margin-left: map-get($marginInPercentage, "per6") !important;
  margin-right: map-get($marginInPercentage, "per6") !important;
}

.popupContainer {
  width: map-get($marginInPercentage, "per28o5");
}

.contentLoading {
  height: map-get($marginInPixel, "px392");
}
