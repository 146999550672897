@import "../../../../utils/styles/fonts.scss";
@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.filtersSection {
    display: flex;
    height: map-get($marginInPixel, "px34");
}

.pageContentRight {
    width: map-get($marginInPercentage, "per58");
    align-items: center;
    display: flex;
    gap: map-get($marginInPercentage, "per1o2");
    justify-content: flex-end;
}

.pageContentLeft {
    width: map-get($marginInPercentage, "per42");
    align-items: center;
    display: flex;
}

.pageContentRight .filterButton {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: map-get($marginInPixel, "px5");
    width: map-get($marginInPixel, "px32");
    height: map-get($marginInPixel, "px32");
    &:disabled {
        opacity: 0.5;
    }
    &:disabled:hover {
        background: none;
    }
    img {
        margin-top: 0;
    }
}

.pageContentRight .filterButton:hover {
    background: map-get($buttons, "contentPrimaryhover");
    border-radius: map-get($marginInPixel, "px8");
}

.pageContentRight .filterButton:active {
    background: map-get($buttons, "contentPrimaryfocus");
    border-radius: map-get($marginInPixel, "px8");
}

.configContainer {
    background-color: $white;
    position: relative;
    margin-top: map-get($marginInPixel, "px16");
    height: calc(100vh - 378px);
    overflow-y: auto;
}

.datePicker {
    max-width: map-get($marginInPercentage, "per44");
}

.searchField {
    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
}

.accordionCustomClass {
    :global {
      & .collapsed.MuiAccordionSummary-root {
        border: map-get($marginInPixel, "px1") solid $borderGrey;
        height: map-get($marginInPixel, "px48");
      }
      & .expanded.MuiAccordionSummary-root {
        border-bottom: map-get($marginInPixel, "px1") solid $borderGrey;
        border-radius: unset;
        height: map-get($marginInPixel, "px48");
      }
      & .expanded.MuiAccordionSummary-root:hover {
       background-color: unset !important;
      }
      & .accordionCheckBoxClass.MuiAccordionSummary-root {
        border: map-get($marginInPixel, "px1") solid $borderGrey !important;
      }
      & .MuiAccordionSummary-root {
        border-radius: map-get($marginInPixel, "px8");
      }
    }
}

.innerAccordionCustomClass {
    :global {
        & .collapsed.MuiAccordionSummary-root {
            border: none;
            height: map-get($marginInPixel, "px48");
            border-radius: map-get($marginInPixel, "px8");
            background-color: $subTableHeaderBackground;
        }
        & .expanded.MuiAccordionSummary-root {
            border-bottom: unset;
            height: map-get($marginInPixel, "px48");
            border-radius: map-get($marginInPixel, "px8");
            background-color: $subTableHeaderBackground
        }
        & .expanded.MuiAccordionSummary-root:hover {
            background-color: $cardHover !important;
        }
    }
}

.innerAccordionWrapper {
    padding: map-get($marginInPixel, "px12");
    min-height: map-get($marginInPixel, "px135");
}

.activityCardContainer {
    padding-top: map-get($marginInPixel, "px1");
}

.actionIcon {
    margin-top: 0;
}

.noConfig {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: map-get($weight, "bolder");
    font-size: map-get($size, "px18");
    line-height: map-get($marginInPixel, "px26");
}

.confirmDeployPopup {
    width: map-get($marginInPercentage, "per42");
}

.selectedCountText {
    margin-right: map-get($marginInPixel, "px12");
    color: $textLabel;
}

.headerActionButtons {
    display: flex;
    gap: map-get($marginInPixel, "px4");
}

.headerActionButtons button{
    padding: map-get($marginInPixel, "px8");
}

.headerActionButtons button:hover{
    background-color: map-get($buttons, "contentPrimaryhover");
    border-radius: map-get($marginInPixel, "px8");
}

.headerActionButtons button:active {
    background-color: map-get($buttons, "contentPrimaryfocus");
    border-radius: map-get($marginInPixel, "px8");
}

.headerActionButtons button.activeButton {
    background-color: map-get($buttons, "contentPrimaryfocus");
    border-radius: map-get($marginInPixel, "px8");
}

.assetErrorPanel {
    position: static;
    display: block;
    transform: none;
    text-align: center;
    padding-top: map-get($marginInPixel, "px16");
}
