@import "../../../../utils/styles/fonts.scss";
@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.message {
  display: block;
  font-size: map-get($marginInPixel, "px22");
  line-height: map-get($marginInPixel, "px26");
  margin-top: map-get($marginInPixel, "px24");
}

.noUsersDetails{
  margin-top: map-get($marginInPixel, "px66");
  margin-left: map-get($marginInPixel, "px66");
}

.heading {
  font-size: map-get($marginInPixel, "px40");
  line-height: map-get($marginInPixel, "px46");
}

.title {
  margin-top: 16px;
  margin-left: 24px;
  display: flex;
  gap: map-get($marginInPixel, "px10");
}

.iconLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-size: map-get($size, "px22");
}
