@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.actionsCell {
  align-items: center;
  display: flex;
  gap: map-get($marginInPercentage, "per19");
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
}

.notesIcon{
  margin-left: 12px;
}

.buttonContainer {
  display: flex;
  gap: map-get($marginInPixel, "px6");
  .selectedItemCount {
    font-size: map-get($marginInPixel, "px14");
    line-height: map-get($marginInPixel, "px18");
    font-weight: normal;
    margin: map-get($marginInPixel, "px8");
  }
  .iconStyle {
    :disabled{
      opacity: 0.5;
    }
  }
}

.actionIcon {
  padding: map-get($marginInPixel, "px8");
  border-radius: map-get($marginInPixel, "px8");
  margin-top: 0;
}

.actionsCell button {
  padding: 0;
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
}

.actionIcon:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
}

.actionIcon:active {
  background-color: map-get($buttons, "contentPrimaryfocus");
}

.blankAction {
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
}