@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.fieldLabel {
  color: $textLabel;
  margin-right: map-get($margins, "px8");
}

.fieldValue {
  color: $textValue;
  font-weight: map-get($weight, "bold");
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipContainer {
  display: flex;
}

.tooltipFieldValue {
  font-weight: normal !important;
  @extend .fieldValue;
}
