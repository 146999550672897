@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.count {
  text-align: left;
}

.iconLabel {
  display: flex;
  height: map-get($margins, "px18");
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.iconWithoutLabel {
  justify-content: flex-start;
}

.iconLabelWrapper {
  display: flex;
}

.icon {
  margin-top: map-get($marginInPixel, "px2");
}

:global(.emptyBar) {
  width: map-get($marginInPercentage, "per100");
  margin-top: map-get($marginInPixel, "px2");
  height: map-get($marginInPixel, "px4");
  display: block;
  background-color: $noteReceiveBg;
}