@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.notificationContainer {
  height: map-get($marginInPixel, "px20");
  background-color: $notificationBar;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($marginInPixel, "px2") map-get($marginInPixel, "px16") map-get($marginInPixel, "px2")
    map-get($marginInPixel, "px16");
}

.warningInfo {
  margin-right: map-get($marginInPixel, "px10");
  width: map-get($marginInPixel, "px12");
  height: map-get($marginInPixel, "px12");
  margin-top: 0;
}

.notificationText {
  white-space: nowrap;
  font-size: map-get($size, "px12");
  margin-right: map-get($marginInPixel, "px3");
}

.noteText {
  font-weight: map-get($weight, "bold");
  font-size: map-get($size, "px12");
  width: map-get($marginInPercentage, "per75");
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
