@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.selectedFilters {
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-wrap: wrap;
}

.displayLabel {
  position: relative;
  top: -6px;
  font-weight: map-get($weight, "normal");
  color: #313235;
}

.seperator {
  display: inline-flex;
  position: relative;
  width: map-get($marginInPixel, "px4");
  justify-content: center;
  border-right: map-get($marginInPixel, "px1") solid #55595E;
  height: map-get($marginInPixel, "px16");
  margin-right: map-get($marginInPixel, "px4");
}

.filterLabel {
  display: inline-block;
  position: relative;
  font-weight: map-get($weight, "normal");
  color: #55595E;
  text-transform:capitalize
}

.menuDisplayLabel {
  display: inline-block;
  position: relative;
  font-weight: map-get($weight, "normal");
  color: #313235;
}

.filter {
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px12");
  background-color: $filterItemBackground;
  color: $noResultText;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px14");
  font-size: map-get($marginInPixel, "px14");
  line-height: map-get($marginInPixel, "px20");
  height: map-get($marginInPixel, "px28");
  align-items: center;
  margin-left: map-get($marginInPixel, "px8");
  .filterLabel {
    top: -6px;
  }
  .seperator {
    top: -2px;
  }
  .menuDisplayLabel {
    top: -6px;
  }
}


.disableFilter {
  padding: map-get($marginInPixel, "px2") map-get($marginInPixel, "px12");
  background-color: $filterItemBackground;
  color: $noResultText;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px17");
  font-size: map-get($marginInPixel, "px14");
  line-height: map-get($marginInPixel, "px18");
  height: map-get($marginInPixel, "px26");
  align-items: center;
  margin-left: map-get($marginInPixel, "px8");
  .filterLabel {
    top: -2px;
  }
  .seperator {
    top: map-get($marginInPixel, "px2");
  }
  .menuDisplayLabel {
    top: -2px;
  }
}

.badge {
  border: none;
  outline: none;
  padding: map-get($marginInPixel, "px2") map-get($marginInPixel, "px6");
  background-color: $secondary;
  color: $badgeText;
  font-size: map-get($marginInPixel, "px14");
  line-height: map-get($marginInPixel, "px18");
  border-radius: map-get($marginInPixel, "px16");
  margin-left: map-get($marginInPixel, "px10");
  height: map-get($marginInPixel, "px21");
  max-width: map-get($marginInPixel, "px38");
  justify-content: center;
  align-items: center;
  font-weight: 700;
  letter-spacing: map-get($marginInPixel, "px1");
  cursor: pointer;
}

.clearAll {
  border: none;
  background: none;
  color: $secondary;
  margin-left: map-get($marginInPixel, "px10");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px8");
  cursor: pointer;
}

.closeIcon {
  min-height: map-get($marginInPixel, "px16");
  margin-left: map-get($marginInPixel, "px8");
  margin-top: map-get($marginInPixel, "px4");
}

.closeButton {
  background: none;
  border: none;
  outline: none;
  padding: map-get($marginInPixel, "px1") 0;
  cursor: pointer;
  position: relative;
  top: -3px;
}

.closeButtonContainer {
  position: absolute;
  display: inline-block;
  right: map-get($marginInPixel, "px6");
  top: map-get($marginInPixel, "px1");
}

.dropdown {
  max-height: map-get($marginInPixel, "px156");
  overflow-y: auto;
  .options {
    margin: map-get($marginInPixel, "px12") 0;

    .filterMenuItem {
      padding: map-get($marginInPixel, "px2") map-get($marginInPixel, "px12");
      background-color: $filterItemBackground;
      color: $noResultText;
      border: map-get($marginInPixel, "px1") solid $textFieldOutline;
      border-radius: map-get($marginInPixel, "px14");
      font-size: map-get($marginInPixel, "px14");
      line-height: map-get($marginInPixel, "px20");
      height: map-get($marginInPixel, "px28");
      align-items: center;
      position: relative;
      .filterLabel {
        top: 0;
      }
      .seperator {
        top: map-get($marginInPixel, "px4");
      }
      .menuDisplayLabel {
        top: 0;
      }
      .closeButton {
        background: none;
        border: none;
        outline: none;
        padding: map-get($marginInPixel, "px1") 0;
        cursor: pointer;
        top: -1px;
        float: right;
      }
    }
  }
}

.clearAllButton {
  background: none;
  outline: none;
  border: none;
  color: $secondary;
  margin-bottom: map-get($marginInPixel, "px8");
  cursor: pointer;
}
