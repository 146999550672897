@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.messageContainer {
  margin: map-get($marginInPixel, "px16") 0 map-get($marginInPixel, "px16") 0;
  font-weight: normal;
  line-height: map-get($marginInPixel, "px24");
}

.addNote {
  color: $textLabel;
  margin-bottom: map-get($marginInPixel, "px4");
}

.instructionText {
  display: block;
  color: $textLabel;
  font-size: map-get($marginInPixel, "px14");
  font-weight: normal;
  line-height: map-get($marginInPixel, "px20");
  margin-top: map-get($marginInPixel, "px2");
}
.textFieldContainer {
  width: map-get($marginInPercentage, "per100");
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: map-get($marginInPixel, "px18")
}

.textField {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px82");
  resize: none;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px4");
  outline: $textFieldOutline;
  color: $noResultText;
  background-color: $filterItemBackground;
}

.popupStyles {
  width: map-get($marginInPercentage, "per30");
  margin-bottom: map-get($marginInPixel, "px30") !important;
}

.popupHeader {
  display: block;
  margin-top: map-get($marginInPixel, "px16");
  margin-bottom: map-get($marginInPixel, "px8");
  color: $muteLabel;
}
.popupDemoteHeader {
  display: block;
  margin-top: map-get($marginInPixel, "px18");
  margin-bottom: map-get($marginInPixel, "px8");
  color: $muteLabel;
}