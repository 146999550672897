@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.container {
  height: calc(100vh - 155px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}

.fullScreenContainer {
  height: auto;
  bottom: 0;
  position: fixed;
  right: 0;
}

.overlay {
  background: #18181a;
  bottom: 0;
  left: 0;
  opacity: 0.72;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: map-get($marginInPixel, "px8");
}

.fullScreenOverlay {
  position: fixed;
  border-radius: 0;
}

.popup {
  background: #ffffff;
  position: fixed;
  width: map-get($marginInPixel, "px600");
  height: 298px;
  left: 50%;
  top: 60%;
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.filterPopup {
  width: map-get($marginInPixel, "px600");
}

.filterPopupContent {
  display: grid;
  gap: map-get($marginInPixel, "px24");
  grid-template-columns: auto auto;
  margin-bottom: map-get($marginInPixel, "px24");
  width: fit-content;
}

.popupStyles {
  width: map-get($marginInPixel, "px600");
}

.dropDownWidth {
    width: map-get($marginInPixel, "px264");
}

.warningIcon {
  margin-right: map-get($marginInPixel, "px5");
}

.warningSection{
  display: flex;
}

.confirmText {
  color: $red;
  line-height: map-get($marginInPixel, "px22");
  // margin-bottom: map-get($marginInPixel, "px22");
}
