@import "../../../../../utils/styles/margins.scss";
@import "../../../../../utils/styles/colors.scss";
@import "../../../../../utils/styles/fonts.scss";

.container {
  padding: map-get($marginInPixel, "px24");
  :global {
    & .heading {
      font-size: map-get($size, "px20");
      .left {
        color: $secondary;
      }
      .right {
        font-weight: map-get($weight, "bolder");
        font-size: map-get($size, "px22");
        & img {
          padding: 0 10px;
          margin: 0;
          width: 28px;
          filter: brightness(3) invert(1);
        }
      }
    }
    & .topSection {
      margin-top: map-get($marginInPixel, "px16");
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: map-get($marginInPixel, "px8");
      flex-direction: row;
      height: 50px;
      & .tableSelectionText {
        font-size: map-get($size, "px14");
        margin: auto;
        margin-left: 0;
      }
      & .searchField {
        width: map-get($marginInPercentage, "per30");
        margin-right: 0;
      }
    }
    & .tableSection {
      margin-top: 16px;
      height: calc(100vh - 331px);
      :global {
        & .noRows .customTable {
          height: inherit;
        }
      }
    }
    & .buttonGroup {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      margin-top: map-get($marginInPixel, "px16");
      .saveButton {
        background-color: map-get($buttons, "primary");
        border-radius: map-get($marginInPixel, "px8");
        margin-left: map-get($marginInPixel, "px16");
      }
    }
  }
}
