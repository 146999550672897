@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.distributionPanel > div {
  margin-left: map-get($marginInPercentage, "per10");
}

.distributionPanel > div:first-child {
  margin-left: 0;
}


.distributionPanel {
  display: flex;
  justify-content: space-between;
  width: map-get($marginInPercentage, "per55o5");
}

.fleetData {
  align-items: center;
  color: $cardLabel;
  display: flex;
  font-weight: map-get($weight, "bolder");
  margin-right: map-get($marginInPercentage, "per5");
  width: map-get($marginInPercentage, "per30");
}

.nameIcon {
  height: map-get($margins, "px16");
  margin-right: map-get($margins, "px16");
  width: map-get($margins, "px16");
}

.root:hover{
  background-color: $cardHover;
}

.root:focus{
  background-color: map-get($buttons, "contentPrimaryhover");
}

.root {
  align-items: center;
  background: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  display: flex;
  min-height: map-get($marginInPixel, "px52");
  padding-left: map-get($marginInPixel, "px12");
  padding-right: map-get($marginInPixel, "px12");
}

.fleetSelected{
  border: 1px solid $purple
}

.card{
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.viewIcon {
  margin-left: auto;
}

.viewIconFallback {
  width: map-get($marginInPixel, "px32");
}

.distributionCards {
  width: map-get($marginInPercentage, "per33");
}

.nameLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: inherit;
}

@media (min-width: 1400px) {
  .fleetData {
    width: map-get($marginInPercentage, "per30");
  }
  .distributionPanel {
    width: map-get($marginInPercentage, "per50o5");
  }
}
