@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

:global(.toaster) > div {
  box-shadow: 0px 12px 17px 2px #1e1f2029, 0px 5px 22px 4px #1e1f2029, 0px 7px 8px -4px #1e1f2033;
  border-radius: map-get($marginInPixel, "px8");
  padding: map-get($marginInPixel, "px8") map-get($marginInPixel, "px16");
}

.successColor > div {
  background-color: map-get($toaster, "success");
}

.infoColor > div {
  background-color: map-get($toaster, "info");
}

.lowErrorColor > div {
  background-color: map-get($toaster, "low");
}

.mediumErrorColor > div {
  background-color: map-get($toaster, "medium");
}

.criticalErrorColor > div {
  background-color: map-get($toaster, "critical");
  & .toasterMessage {
    color: map-get($toaster, "criticalText");
  }
}

.buttonIcon {
  align-items: center;
  display: flex;
  margin-left: auto;
  gap: map-get($marginInPixel, "px16");
}

.icon {
  height: map-get($marginInPixel, "px16");
  width: map-get($marginInPixel, "px16");
  margin-top: 0;
}

.iconFill {
  height: map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px24");
  margin-top: 0;
}

.messageIconContainer {
  display: flex;
  width: map-get($marginInPercentage, "per90");
  gap: map-get($marginInPixel, "px8");
  align-items: center;
  padding: map-get($marginInPixel, "px2") 0;
}

.toasterMessage {
  color: $textValue;
  font-family: "GE Sans Pro Sans", sans-serif;
  font-weight: map-get($weight, "normal");
  font-size: map-get($size, "px16");
  line-height: map-get($marginInPixel, "px24");
}

.closeButton {
  height: 0;
}
