@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.pageContainer {
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px24");
  background-color: $white;
  height: calc(100vh - map-get($marginInPixel, "px50"));
  overflow: auto;
}

.iconLabel {
  display: flex;
  align-items: center;
  gap: map-get($marginInPixel, "px8");
}

.labelStyles {
  font-size: map-get($size, "px22");
  padding: map-get($marginInPixel, "px9");
}

.container {
  width: map-get($marginInPercentage, "per50");
  margin-left: map-get($marginInPixel, "px40");
  margin-top: map-get($marginInPixel, "px18");
}

.divider {
  border-bottom: map-get($marginInPixel, "px1") solid #ececf0;
  margin-top: map-get($marginInPixel, "px24");
}

.section {
  padding: map-get($marginInPixel, "px30") map-get($marginInPixel, "px30") 0 0;
  align-items: end;
}

.sectionTitle {
  font-size: map-get($marginInPixel, "px18");
  font-weight: 400;
  line-height: map-get($marginInPixel, "px24");
  margin-bottom: map-get($marginInPixel, "px24");
}

.fieldLabel {
  width: map-get($marginInPercentage, "per20");
  text-align: end;
  padding: map-get($marginInPercentage, "per1");
  margin-right: map-get($marginInPercentage, "per2");
  color: $textLabel;
}

.elementContainer {
  display: flex;
  margin-top: map-get($marginInPixel, "px24");
}

.input {
  border: map-get($marginInPixel, "px1") solid $lightDisabledBackground;
  height: map-get($marginInPixel, "px36");
  background: $lightDisabledBackground;
  width: map-get($marginInPercentage, "per42");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px12") map-get($marginInPixel, "px4")
    map-get($marginInPixel, "px12");
  border-radius: map-get($marginInPixel, "px4");
}

.confirmationPopup {
  width: map-get($marginInPixel, "px600");
}

.confirmationPopupContent {
  margin-bottom: 36px;
}

.languageDropdown {
  width: map-get($marginInPercentage, "per42");
  div {
    width: 100%;
  }
}
