.root {
  display: flex;
  align-items: center;
  :global {
    & .textContainer {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    & .width50 {
      width: 50%;
    }
    & .chipContainer {
      width: 50%;
      text-align: center;
    }
  }
}
