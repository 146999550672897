@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.card:hover{
  background-color: $cardHover;
}

.card:focus{
  background-color: map-get($buttons, "contentPrimaryhover");
}

.card:hover :global(.emptyBar){
  background-color: inherit
}

.card {
  align-items: center;
  background: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($margins, "px8");
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  height: map-get($marginInPixel, "px52");
  width: map-get($marginInPercentage, "per100");
  padding-left: map-get($marginInPercentage, "per1o35");
  padding-right: map-get($marginInPercentage, "per1o35");
}

.cardDetail {
  align-items: center;
  display: flex;
  margin-top: map-get($marginInPixel, "px12");
  width: map-get($marginInPercentage, "per100");
}

.hospitalData {
  align-items: center;
  color: $cardLabel;
  display: flex;
  width: map-get($marginInPercentage, "per15");
  margin-right: map-get($marginInPercentage, "per2");
}

.siteIcon {
  margin-right: map-get($marginInPercentage, "per9");
}

.idData {
  margin-right: map-get($marginInPercentage, "per2");
  width: map-get($marginInPercentage, "per10");
}

.cityData {
  align-items: center;
  width: map-get($marginInPercentage, "per15");
  margin-right: map-get($marginInPercentage, "per1");
}

.distributionData {
  display: flex;
  justify-content: space-between;
  margin-left: map-get($marginInPercentage, "px3");
  width: map-get($marginInPercentage, "per46");
  gap: map-get($marginInPercentage, "per8");
}

.distributionCards {
  width: map-get($marginInPercentage, "per20");
}

.removeLinkStyles {
  text-decoration: none;
  color: inherit;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hospitalData :global(.editWithTooltip):hover {
  padding: 0 map-get($marginInPixel, "px10");
}
