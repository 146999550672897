@import "../../../utils/styles/margins.scss";


.logFiles {
  height: calc(100vh - 332px);
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px12");
}

.loader {
  height: calc(100vh - 330px);
}

.tableSection {
  height: calc(100vh - 365px);
  margin-top: 20px;
}

.mutedActions {
  opacity: 0.5;
}