@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";
@import "../../../../utils/styles/fonts.scss";

.navigatorPanel {
  position: fixed;
  top: map-get($marginInPixel, "px84");
  left: map-get($marginInPixel, "px4");
  bottom: map-get($marginInPixel, "px4");
  background: map-get($siteNavigator, "background");
  box-shadow: 0px 0px 3px 0px #1E1F203D,
    0px 2px 1px -1px #1E1F2029,
    0px 1px 1px 0px #1E1F201F;
  height: calc(100vh - map-get($marginInPixel, "px88"));
  width: map-get($marginInPixel, "px272");
  z-index: 10;
  padding: map-get($marginInPixel, "px16") 0 map-get($marginInPixel, "px16") map-get($marginInPixel, "px16");
  border-radius: map-get($marginInPixel, "px20");
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px4");
}

.navigatorPanel .navigatorLoading {
  background: map-get($siteNavigator, "background");
}

.navigatorSearch {
  margin-right: map-get($marginInPixel, "px16");
  margin-bottom: map-get($marginInPixel, "px16");
}

.customerLabel {
  padding-right: map-get($marginInPixel, "px16");
  display: flex;
  height: map-get($marginInPixel, "px24");
}

.customerName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: map-get($marginInPixel, "px215");
  cursor: pointer;
}

.customerName.disabledCustomerName {
  cursor: unset;
}

.fleetAccordion {
  padding-left: map-get($marginInPixel, "px24");
}

.subFleetAccordion {
  padding-left: map-get($marginInPixel, "px40");
}

.sitePanel {
  overflow-y: auto;
  height: calc(100vh - 124px);
  position: relative;
  background: map-get($siteNavigator, "background");
  padding-right: map-get($marginInPixel, "px16");
  display: flex;
  flex-direction: column;
}

.customerLink {
  display: flex;
  text-decoration: none;
  color: $textValue;
  width: map-get($marginInPercentage, "per100");
  font-weight: map-get($weight, "bold");
  line-height: map-get($marginInPixel, "px24");
}

.siteLabel {
  max-width: map-get($marginInPixel, "px165");
}

.fleetLabel {
  max-width: map-get($marginInPixel, "px150");
}

.subFleetLabel {
  max-width: map-get($marginInPixel, "px135");
}

.noSearchResults {
  padding-top: map-get($marginInPixel, "px8");
  color: $noResultText;
}

.tooltip {
  max-width: map-get($marginInPixel, "px225");
}

:global(.SiteNavigator > .MuiTooltip-tooltipPlacementBottom) {
  margin-top: map-get($marginInPixel, "px12") !important;
}
