@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.labelStyles {
  font-weight: map-get($weight , "bolder");
}

.valueStyles {
  font-weight: map-get($weight , "normal");
}

.tooltipTitleContainer {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px5");
}
