@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.customerID {
  margin-left: map-get($marginInPercentage, "per2");
}

.customerName {
  font-weight: map-get($weight, "bolder");
  width: map-get($marginInPercentage, "per100");
}

.customerViewChange {
  align-items: center;
  display: flex;
  margin-left: auto;
  gap: map-get($marginInPixel, "px8");
}

.customerViewContent {
  align-content: flex-start;
  column-gap: map-get($marginInPixel, "px16");
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 150px);
  overflow-y: auto;
  width: map-get($marginInPercentage, "per100");
}

.customerViewContent.cardContent {
  row-gap: map-get($marginInPixel, "px16");
}

.customerViewContent.listContent {
  row-gap: map-get($marginInPixel, "px12");
}

.customerViewHeader {
  display: flex;
  height: map-get($marginInPixel, "px34");
  margin-bottom: map-get($marginInPixel, "px16");
  width: 100%;
}

.customerViewTitle {
  display: flex;
  width: calc(map-get($marginInPercentage, "per56"));
  align-items: center;
}

.customerViewToggleButtonList {
  background: none;
  border: none;
  outline: none;
  padding: map-get($marginInPixel, "px6");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.customerViewToggleButtonList:hover {
  background: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px8");
}

.customerViewToggleButtonList:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.customerViewToggleButtonCard {
  background: $toggleBackground;
  padding: map-get($marginInPixel, "px6");
  border-radius: map-get($marginInPixel, "px8");
  border: none;
  outline: none;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
}

.searchField {
  outline: none;
  width: map-get($marginInPercentage, "per85");
}

.toggleIcon {
  cursor: pointer;
}

.readOnlyField{
  width: map-get($marginInPercentage, "per20");
  margin-left: map-get($marginInPercentage, "per2");
}

.assetsCountText {
    margin-right: map-get($marginInPixel, "px10");
    align-self: center;
    text-align: right;
}

.popupStyle {
  z-index: 10;
  width: map-get($marginInPercentage, "per35");
}

.inputTextField {
  border-color: $purple !important;
  height: map-get($marginInPixel, "px32");
}

.nosites {
  display: flex;
  justify-content: center;
  align-items: center;
  height: map-get($marginInPixel, "px140");
  border-bottom: 1px solid $borderGrey;
  font-weight: map-get($weight, "bolder");
  font-size: map-get($size, "px18");
  line-height: map-get($marginInPixel, "px24");
}

.tasksLink {
  text-decoration: none;
  color: $secondary;
}
