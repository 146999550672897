@import "../../../../utils/styles/colors.scss";
@import "../../../../utils/styles/margins.scss";

.notInFleetContainer {
  height: map-get($marginInPixel, "px320");
  overflow: auto;
  :global {
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
    }
  }
}

.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}

:global(.AssetsNotInSubFleet .NotInFleet_IconLabel) {
  width: map-get($marginInPercentage, "per30");
}

:global(.AssetsNotInSubFleet .NotInFleet_DistPanel) {
  width: map-get($marginInPercentage, "per32");
}

.notInFleetCardContainer {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  overflow: auto;
  padding: 8px 0;
}

.notInFleetCardContainer > div {
  width: calc(25% - 7px) !important;
}
.notInFleetCardContainer > div > div {
  width: auto !important;
}

.draggingAssetPlaceHolder {
  transform: none !important;
  width: 22%;
  opacity: 10%;
  pointer-events: none;
}
