@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.messageContainer {
  margin-bottom: map-get($marginInPixel, "px12");
  font-weight: map-get($weight, "normal");
  line-height: map-get($marginInPixel, "px24");
}

.addNote {
  color: $textLabel;
  margin-bottom: map-get($marginInPixel, "px8");
}

.instructionText {
  display: block;
  color: $textLabel;
  font-size: map-get($size, "px14");
  font-weight: map-get($weight, "normal");
  line-height: map-get($marginInPixel, "px20");
  margin-top: map-get($marginInPixel, "px4");
}

.textFieldContainer {
  width: map-get($marginInPercentage, "per100");
}

.textField {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px82");
  resize: none;
  border: map-get($marginInPixel, "px1") solid $textFieldOutline;
  border-radius: map-get($marginInPixel, "px4");
  outline: $textFieldOutline;
  color: $noResultText;
  background-color: $filterItemBackground;
  vertical-align: top;
}

.popupStyles {
  width: map-get($marginInPercentage, "per35");
}

.serialNumberWrapper {
  display: flex;
  margin-bottom: map-get($marginInPixel, "px12");
  color: $textValue;
}

.serialNumberLabel {
  white-space: nowrap;
  margin-right: map-get($marginInPixel, "px4");
}

.serialNumber {
  width: map-get($marginInPercentage, "per80");
  font-weight: map-get($weight, "bolder");
  display: -webkit-box;
  max-width: map-get($marginInPercentage, "per80");
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    display: block;
    max-height: map-get($marginInPixel, "px48");
  }
}

.tooltipTitle {
  white-space: pre-line;
  max-width: map-get($marginInPixel, "px271");
}

:global(.ApproveRejectSerialsCodeTooltip > .MuiTooltip-tooltipPlacementBottom > .MuiTooltip-arrow) {
  left: map-get($marginInPixel, "px110") !important;
}

.reqIcon {
  width: map-get($marginInPixel, "px6");
  min-height: map-get($marginInPixel, "px18");
  margin-left:map-get($marginInPixel, "px2");
}
