@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.Active {
  font-weight: map-get($weight, "bolder");
}

.ArrowIcon {
  padding: map-get($marginInPixel, "px4") 0;
  width: map-get($marginInPixel, "px16");
  text-align: center;
  height: map-get($marginInPixel, "px24");
}

.ArrowIcon img {
  margin-top: 0;
}

.BreadcrumbIcon {
  margin-top: 0;
  padding: 0 map-get($marginInPixel, "px2");
}

.Breadcrumbs {
  display: flex;
  align-items: center;
  max-width: map-get($marginInPercentage, "per18");
}

.Card {
  align-items: center;
  background: $breadcrumbBg;
  border-bottom: map-get($marginInPixel, "px1") solid $borderGrey;
  display: flex;
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPercentage, "per100");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px16");
}

.Link {
  color: $secondary;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px map-get($marginInPixel, "px8");
  line-height: map-get($marginInPixel, "px24");
}

.Text {
  color: $breadcrumb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px map-get($marginInPixel, "px8");
  line-height: map-get($marginInPixel, "px24");
}

.navigatorBtn button {
  padding: 0;
  height: map-get($marginInPixel, "px20");
  width: map-get($marginInPixel, "px20");
}

.navigatorBtn button.activeSiteNavigator {
  background: map-get($siteNavigator, "breadcrumbIconOpen");
  border-radius: map-get($marginInPixel, "px4");
}
.Link:hover {
  background: map-get($buttons, "textPrimaryBackground");
  border-radius: map-get($marginInPixel, "px4");
}
.Link:focus {
  background:  map-get($buttons, "focus");
  border-radius: map-get($marginInPixel, "px4");
}
.navigatorBtn button:hover {
  background: map-get($buttons, "textPrimaryBackground");
  border-radius: map-get($marginInPixel, "px4");
}

.disabled {
  color: map-get($sideNavigation, "disabledText");
}
