@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.errorMessage{
    text-align: left;
    display: flex;
    flex-direction: row;
    font-weight: map-get($weight, "normal" );
    gap: map-get($marginInPixel, "px4");
    color: map-get($inputValidation, errorBorder);
    size: map-get($marginInPixel, "px14");
    line-height: map-get($marginInPixel, "px18");
    width: map-get($marginInPixel, "px274");
}