@import "../../../utils/styles/margins.scss";

.topSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

:global(.assetMutedBar) .statusTable {
  height: calc(100vh - 404px);
}

.statusTable {
  height: calc(100vh - 384px);
  overflow-y: auto;
  margin-top: map-get($marginInPixel, "px20");
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: inherit;
    }
    & .customTable.loaderCustomTable {
      height: calc(100vh - 385px);
    }
    & .tableLoader {
      height: calc(100vh - 394px);
    }
  }
}

.loader {
  height: calc(100vh - 330px);
}

.searchField {
  width: map-get($marginInPercentage, "per16o5");
}