@import "../../utils/styles/margins.scss";

.bar {
  height: map-get($margins, "px4");
  margin-right: map-get($margins, "px2");
}

.bar:last-child {
  margin-right: 0;
}
