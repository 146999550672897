@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.rightAlign {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  height: map-get($marginInPixel, "px40");
  min-width: map-get($marginInPixel,"px150");
  position: relative;
}

.rightInnerAlign {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: map-get($marginInPixel, "px8");
  padding: map-get($marginInPixel, "px8");
}

.userIcon {
  height: map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px24");
}

.userNameText {
  cursor: pointer;
  color: $label;
  height: map-get($marginInPixel, "px22");
  line-height: map-get($marginInPixel, "px22");
  font-size: map-get($size, "px16");
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconStyles {
  min-height: auto;
}

:global(.menuOpen) .rightAlign {
  background-color: $activeUserBackground;
  border-radius: map-get($marginInPixel, "px8");
}

:global(.menuOpen) .activeIndicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: map-get($marginInPixel, "px4");
}

:global(.UserMenuItem) .menuItemIcon {
  height: map-get($marginInPixel, "px16");
  width: map-get($marginInPixel, "px16");
  margin-right: 0;
}

:global(.UserMenuItem) .menuItemLabel {
  color: $textValue;
  cursor: pointer;
}

:global(.UserMenuItem) {
  gap: map-get($marginInPixel, "px8");
  height: map-get($marginInPixel, "px38");
}

.userProfileButton > button {
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px8") map-get($marginInPixel, "px4") 0;
}
