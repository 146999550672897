@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.assetDetailsHeader {
  background: $white;
  display: flex;
  justify-content: space-around;
  padding: 16px 0 0 16px;
}

.assetInformation {
  display: flex;
  flex-wrap: wrap;
  width: map-get($marginInPercentage, "per95");
}

.assetName {
  font-weight: map-get($weight, "bolder");
  margin-left: 0;
  margin-right: map-get($margins, "px12");
  cursor: pointer;
}

.assetStatusIndicator {
  margin-right: map-get($margins, "px5");
}

.assetTitleSection {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 map-get($margins, "px2") map-get($margins, "px3") 0;
  width: map-get($marginInPercentage, "per100");
}

.detailsSection {
  flex: 1 1 auto;
  line-height: 2;
  margin-left: map-get($margins, "px16");
}

.itemImage {
  align-items: center;
  background: $border;
  border-radius: 3px;
  display: flex;
  flex: 0 0 140px;
  justify-content: center;
}

.properties {
  line-height: 1.5;
  margin-bottom: map-get($margins, "px3");
  margin-top: map-get($margins, "px3");
}

.spaceBetweenProperties {
  align-items: center;
  display: flex;
  margin-right: map-get($margins, "px36");
}

.assetTitleSectionActions {
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px16");
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPercentage, "per25");
  margin-right: map-get($marginInPixel, "px16");
}

.assetTitleSectionLeft {
  display: flex;
  width: map-get($marginInPercentage, "per75");
}

.editButton {
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
}

.editIcon {
  padding: map-get($marginInPixel, "px8");
  border-radius: map-get($marginInPixel, "px8");
  border: 1px solid $purple;
}

.editIcon:hover {
  background: $editIconHover;
}

.imageStyles {
  width: map-get($marginInPixel, "px150");
  margin: auto;
  height: map-get($marginInPixel, "px120");
}
