@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";

@mixin unSelectableText {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@mixin swStatusOrConfigStatusChip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: map-get($marginInPixel, "px20");
  padding: 0 map-get($marginInPixel, "px8") 0 map-get($marginInPixel, "px8");
  border-radius: map-get($marginInPixel, "px20");
  font-size: map-get($size, "px14");
  line-height: map-get($marginInPixel, "px20");
  width: fit-content;
}
