@import "../../../utils/styles/colors.scss";
@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/margins.scss";

.statusFrame {
  width: map-get($marginInPercentage, "per100");
}

.statusTypeData {
  display: flex;
  justify-content: space-between;
  width: map-get($marginInPercentage, "per100");
}

.statusType {
  color: $textValue;
  line-height: map-get($marginInPixel, "px20");
  white-space: nowrap;
}

.bar {
  background: map-get($bars, "background");
  display: block;
  margin-top: map-get($marginInPixel, "px2");
  width: map-get($marginInPercentage, "per100");
}
