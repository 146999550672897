@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.icon {
    margin: 0;
}

.searchContainer {
    position: relative;
}

.searchMenuContainer {
    position: absolute;
    width: 100%;
    background: $filterItemBackground;
    border-radius: map-get($marginInPixel, "px4");
    margin-top: map-get($marginInPixel, "px4");
    padding: map-get($marginInPixel, "px2") 0;
    box-shadow: 0px 1px 5px 0px $tooltipBoxShadow;
    z-index: 100;
    text-align: start;
}

.searchMenuItem {
    display: flex;
    align-items: flex-start;
}

.searchMenuText  {
    margin-left: map-get($marginInPixel, "px8");
    line-height: map-get($marginInPixel, "px24");
    white-space: pre-line;
}

.searchMenuIconDiv {
    display: flex;
    align-items: center;
    height: map-get($marginInPixel, "px24");
}

.menuIcon {
    margin-top: 0;
}
