@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.container {
  height: calc(100vh - 240px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}

.overlay {
  background: #18181a;
  bottom: 0;
  left: 0;
  opacity: 0.72;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: map-get($marginInPixel, "px8");
}

.filterPopupContent {
  display: grid;
  gap: map-get($marginInPixel, "px24");
  grid-template-columns: auto auto;
  margin-bottom: map-get($marginInPixel, "px24");
  width: fit-content;
}
