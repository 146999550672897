@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.children {
  display: block;
}
.popupButton {
  display: flex;
  margin-top: map-get($marginInPixel, "px24");
  justify-content: space-between;
}
.leftAlignButton {
  display: flex;
  justify-content: flex-start;
}
.rightAlignButton {
  display: flex;
  gap: map-get($marginInPixel, "px16");
  justify-content: flex-end;
}
.leftAlignButton > button {
  width: map-get($marginInPercentage, "per100");
  border: none;
  margin-left: -(map-get($marginInPixel, "px18"));
  background: none;
  background-color: transparent;
  padding-left: 0;
  &:hover {
    background: none;
    background-color: transparent;
  }
  &:disabled {
    background: none;
    background-color: transparent;
  }
  &:enabled {
    background: none;
    background-color: transparent;
  }
}
.rightAlignButton > button {
  width: map-get($marginInPercentage, "per100");
}
.popupContent > button {
  position: absolute;
}
.iconFill {
  right: map-get($marginInPixel, "px7");
  top: map-get($marginInPixel, "px7");
}
.popupContent {
  background: $white;
  left: map-get($marginInPercentage, "per50");
  padding: map-get($marginInPixel, "px14") map-get($marginInPixel, "px24")
    map-get($marginInPixel, "px24") map-get($marginInPixel, "px24");
  position: absolute;
  top: map-get($marginInPercentage, "per50");
  transform: translate(-50%, -50%);
  min-width: map-get($marginInPercentage, "per28");
  border-radius: map-get($marginInPixel, "px16");
}
.popupHeading {
  line-height: map-get($marginInPixel, "px28");
  margin-bottom: map-get($marginInPixel, "px14");
  margin-top: 0;
  font-size: map-get($size, "px22");
  font-weight: map-get($weight, "bold");
  color: $textValue;
}

.buttonContainer {
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px8");
}

.buttonContainer:has(> :global(.Mui-disabled)) {
  background-color: map-get($buttons, "disabledBackground");
}

.buttonContainer button {
  min-width: map-get($marginInPixel, "px81");
}

.contentLoading {
  height: map-get($marginInPixel, "px264");
}
