@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.main {
    display: flex;
    align-items: center;
    height: inherit;
    background: $white;
}

.errorContainer {
    margin-left: map-get($marginInPercentage, "per10");
}

.errorBoundaryContainer {
    height: 100vh;
}

.title {
    font-size: map-get($size, "px40");
    margin: map-get($margins, "px24") 0;
}

.messageContainer {
    font-size: map-get($size, "px22");
    line-height: map-get($margins, "px28");
    margin-bottom: map-get($margins, "px24");
}

.refreshButton button {
    width: map-get($margins, "px130");
}

.errorIcon {
    height: map-get($marginInPixel, "px32");
    width: map-get($marginInPixel, "px32");
}

.refreshIcon {
    margin-right: map-get($margins, "px10");
}

.authContainer {
    height: calc(100vh - 47px);
    padding-left: map-get($marginInPixel, "px120");
    padding-top: map-get($marginInPixel, "px63");
    background: $white;
}

.authContainer .heading {
    font-size: map-get($marginInPixel, "px40");
}

.authContainer .message {
    display: block;
    font-size: map-get($marginInPixel, "px22");
    margin-top: map-get($marginInPixel, "px24");
}
.buttonContainer {
    background-color: $secondary;
    border-radius:  map-get($marginInPixel, "px8");
    width:  map-get($marginInPixel, "px111");
    button {
        width:  map-get($marginInPixel, "px111");
      }
 }