@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/mixins.scss";

.activeRow {
  background-color: $tableActiveRowBackground;
}

.bodyRowStyles {
  text-align: left;
}

.cardColorBarStyles {
  height: map-get($marginInPixel, "px48");
}

.checkboxStyles {
  align-self: center;
  height: map-get($marginInPixel, "px16");
  left: map-get($marginInPixel, "px12");
}

.selectionCell {
  padding-left: 0 !important;
}

.columnCellStyles {
  padding: 0 map-get($marginInPixel, "px12");
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: table-cell;
  max-width: 0;
  vertical-align: middle;
  position: relative;
}

.tooltipTitle {
  white-space: pre-line;
  opacity: 1;
  z-index: 9999;
}

.columnHeaderStyles {
  @include unSelectableText;
  color: $textValue;
  font-size: map-get($margins, "px16");
  font-weight: map-get($weight, "bold");
  padding: map-get($marginInPixel, "px12");
  white-space: nowrap;
  cursor: pointer;
  display: table-cell;
  height: map-get($marginInPixel, "px48");
  vertical-align: middle;
}

.headerContainerStyles {
  background: $white;
  position: sticky;
  top: 0;
  z-index: 9;
  display: table-header-group;
  box-shadow: inset 0 0 0 $primary, inset 0 -1px 0 $primary;
  & tr {
    border-bottom: map-get($marginInPixel, "px1") solid $primary;
  }
}

.headerRowStyles {
  text-align: left;
  display: table-row;
  &:hover {
    :global {
      & .resizerOnHover {
        opacity: 1;
      }
    }
  }
}

.rotateSortIconStyles {
  margin-left: map-get($marginInPixel, "px4");
  margin-right: map-get($marginInPixel, "px4");
  transform: rotate(180deg);
  vertical-align: middle;
}

.selectionColumnStyles {
  display: flex;
  height: map-get($marginInPixel, "px48");
  justify-content: flex-end;
  :global {
    & div.dragIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      cursor: pointer;
      visibility: hidden;
      &:hover {
        visibility: visible;
      }
    }
    & div.dragDisabled {
      visibility: hidden !important;
    }
    & .customCardColorBar {
      border-radius: unset;
    }
  }
}

.sortIconStyles {
  margin-left: map-get($marginInPixel, "px4");
  margin-right: map-get($marginInPixel, "px4");
  vertical-align: middle;
}

.tableContainerStyles {
  height: inherit;
  overflow: auto !important;
  width: map-get($marginInPercentage, "per100");
}

.noRows {
  height: inherit;
  :global {
    & .customTable {
      height: inherit;
    }
  }
}

.tableStyles {
  width: map-get($marginInPercentage, "per100");
  border-collapse: collapse;
  display: table;
}

.tableRowHover {
  &:hover {
    box-shadow: none;
    background-color: #ededee;
    :global {
      div.dragIcon {
        visibility: visible;
      }
    }
  }
  &:active {
    box-shadow: 0px 0px 0px 1px $secondary inset;
    :global {
      div.dragIcon {
        visibility: visible;
      }
    }
  }
}

.tableRow {
  height: map-get($marginInPixel, "px45");
  align-items: center;
  border-bottom: map-get($marginInPixel, "px1") solid $gridHR;
  width: map-get($marginInPercentage, "per100");
  display: table-row;
  &::after {
    background-color: #f3fcff;
  }
  :global {
    & .container {
      padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px24");
    }
  }
}

.dragRowPlaceHolder {
  border-radius: 3px;
  width: 100%;
  display: table-row;
  transform: none !important;
  opacity: 10%;
  pointer-events: none;
}

.tbody {
  display: table-row-group;
  position: relative;
  :global {
    & .subRow:not(.firstChildTable) {
      border-bottom: unset;
    }
  }
}

.draggingRow {
  div[role="cell"] {
    max-width: unset;
    width: 70px;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loaderContainer {
  height: map-get($marginInPixel, "px250");
}

:global(.subRow) .headerContainerStyles {
  z-index: 1;
  background-color: $subTableHeaderBackground;
}

:global(.firstChildTable) {
  border: map-get($marginInPixel, "px1") solid $expandedRowBorder;
  border-top: none;
}

.expandedRow {
  background-color: $subTableHeaderBackground;
  border: map-get($marginInPixel, "px1") solid $expandedRowBorder;
  border-bottom: map-get($marginInPixel, "px1") solid $gridHR;
}

.checkboxStateIcons {
  margin-top: 0;
}

.disabledCheckboxTooltip {
  opacity: 0;
  width: map-get($marginInPixel, "px16");
  height: map-get($marginInPixel, "px16");
  position: absolute;
  left: 100%;
  top: 50%;
  translate: -100% -50%;
}

.resizerSpan {
  width: map-get($marginInPixel, "px8");
  position: absolute;
  height: inherit;
  margin-left: -7px;
  top: -1px;
}

.resizer {
  width: map-get($marginInPixel, "px1");
  height: map-get($marginInPixel, "px24");
  border-radius: 1px;
  background-color: #80878e;

  margin: auto;
  margin-right: -12px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;
  &:active {
    width: map-get($marginInPixel, "px2");
    height: map-get($marginInPixel, "px48");
    position: absolute;
    right: -1px;
    background-color: #7252bc;
  }
}

.isResizing {
  cursor: col-resize;
  opacity: 1;
}

.columnHeaderParent {
  display: flex;
  align-items: center;
  position: relative;
}

.headerCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: table-cell;
  max-width: fit-content;
  vertical-align: middle;
  position: relative;
}

.resizingRowCell {
  border-right: 2px solid #7252bc;
}

.hoveredOnResize {
  border-right: 1px solid #7252bc;
}
