@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/colors.scss";
@import "../../../utils/styles/fonts.scss";

.topSection {
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px10");
  align-items: center;
}

.tableContainer {
  overflow: auto;
  height: calc(100vh - 384px);
  margin-top: map-get($marginInPixel, "px20");
  background-color: $white;
}

:global(.assetMutedBar) .tableContainer {
  height: calc(100vh - 404px);
}

.configTable {
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: calc(100vh - 386px);
    }
    & .tableLoader {
      height: calc(100vh - 394px);
    }
  }
}

.configButton {
  width: map-get($marginInPercentage, "per17");
}

.confirmDeployPopup {
  width: map-get($marginInPercentage, "per42");
}

.information {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px12");
  margin-top: map-get($marginInPixel, "px26");
  font-style: italic;
}

.actionIcon {
  height: map-get($marginInPixel, "px14");
  margin-right: map-get($marginInPixel, "px8");
  width: map-get($marginInPixel, "px14");
}

.loader {
  height: calc(100vh - 330px);
}

.searchField {
  width: map-get($marginInPercentage, "per17");
}

.tabContainer {
  display: flex;
}

.tabLeftContainer {
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  background-color: $white;
  width: map-get($marginInPercentage, "per70");
  padding: 1rem;
}

.tabRightContainer {
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  background-color: $white;
  width: map-get($marginInPercentage, "per29");
  margin-left: map-get($marginInPercentage, "per1");
  display: flex;
  flex-direction: column;
  height: calc(100vh - 298px);
}

.header {
  margin: 1rem;
  font-size: map-get($marginInPixel, "px20");
  font-weight: map-get($weight, "bold");
  line-height: map-get($marginInPixel, "px28");
}

.content {
  padding: 0 1rem;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.rightContainerFooter {
  position: relative;
  bottom: 0;
  height: map-get($marginInPixel, "px120");
  background-color: white;
  border-top: map-get($marginInPixel, "px1") solid #d1d2d4;
  border-bottom-right-radius: map-get($marginInPixel, "px8");
  border-bottom-left-radius: map-get($marginInPixel, "px8");
}

.navigatorContainar {
  height: map-get($marginInPixel, "px32");
  display: inline-block;
  padding: 1rem;
}

.navigator {
  text-decoration: none;
  display: inline-flex;
  gap: map-get($marginInPixel, "px4");
  color: #7252bc;
  .navigator :focus,
  .navigator :active {
    color: inherit;
    color: #7252bc;
  }
}

.disabledNavigator {
  opacity: 0.5;
  text-decoration: none;
  display: inline-flex;
  gap: map-get($marginInPixel, "px4");
  pointer-events: none;
  color: map-get($sideNavigation, "disabledText");
  cursor: pointer;
  .navigator :focus,
  .navigator :active {
    color: inherit;
  }
}

.footerDescription {
  font-size: map-get($marginInPixel, "px16");
  line-height: map-get($marginInPixel, "px24");
  font-weight: map-get($weight, "normal");
  padding: map-get($marginInPixel, "px12");
}
