@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.assetDetails:hover {
  background-color: #ededee;
}
.assetDetails {
  border: 1px solid #c5c7c9;
  border-radius: map-get($marginInPixel, "px8");
  display: flex;
  &:hover .assetDragIcon {
    visibility: visible;
  }
}
.selectedCard {
  background-color: $lightGray;
}
.assetContent {
  margin: map-get($marginInPixel, "px10") map-get($marginInPercentage, "per3");
  width: map-get($marginInPercentage, "per100");
  position: relative;
}
.assetTitle {
  align-items: center;
  display: flex;
  font-weight: map-get($weight, "bolder");
  :global {
    & .assetIcon {
      height: map-get($marginInPixel, "px16");
      width: map-get($marginInPixel, "px14");
      margin-right: unset;
    }
  }
}
.severity {
  margin-left: map-get($marginInPercentage, "per3");
}
.assetCheckbox {
  position: absolute;
  right: 0;
}
.assetDragIcon {
  cursor: pointer !important;
  visibility: hidden;
  position: absolute;
  right: -8px;
  transform: translateY(-25%);
  top: 50%;
  width: map-get($marginInPixel, "px16");
  text-align: center;
}
.assetsField {
  display: flex;
  font-size: map-get($size, "px12");
  line-height: map-get($marginInPixel, "px16");
  margin-top: map-get($marginInPixel, "px8");
}
.assetIconLabel {
  line-height: map-get($marginInPixel, "px22");
  margin-left: map-get($marginInPercentage, "per3");
  margin-right: map-get($marginInPercentage, "per3");
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: map-get($marginInPercentage, "per32");
}
.assetsFieldLabel {
  margin-right: map-get($marginInPercentage, "per4");
  font-weight: map-get($weight, "bold");
}
.removeLinkStyles {
  text-decoration: none;
  color: inherit;
}

.iconStylesMuted {
  opacity: 0.5;
  align-items: center;
  display: flex;
  gap: map-get($marginInPixel, "px6");
}

.iconStyles {
  align-items: center;
  display: flex;
  gap: map-get($marginInPixel, "px6");
}

.assetsFieldMuted {
  opacity: 0.5;
  display: flex;
  font-size: map-get($size, "px12");
  line-height: map-get($marginInPixel, "px16");
  margin-top: map-get($marginInPixel, "px8");
}

