@import "../../utils/styles/margins.scss";

.icons {
  height: map-get($marginInPixel, "px16");
  width: map-get($marginInPixel, "px16");
}
.rootIndicator {
  align-items: center;
  display: flex;
}
