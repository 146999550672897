@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.GatewayConfigurationContainer {
  width: map-get($marginInPercentage, "per100");
  height: calc(100vh - 200px);
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}

.gatewayTable {
  width: map-get($marginInPercentage, "per35");
  border-collapse: separate;
  border-spacing: map-get($marginInPixel, "px4") map-get($marginInPixel, "px24");
}

.buttonContainer {
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px8");
  margin-left: map-get($marginInPixel, "px16");
}

.buttonContainer:hover {
  height: map-get($marginInPixel, "px34");
}

.buttonGroup {
  width: map-get($marginInPercentage, "per35");
  padding: map-get($marginInPixel, "px4");
  display: flex;
  justify-content: end;
}
