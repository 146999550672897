@import "../../utils/styles/margins.scss";

.paginationLoader {
  margin-right: map-get($marginInPixel, "px5");
  vertical-align: text-top;
}

.paginationLoaderContainer {
  position: absolute;
  left: map-get($marginInPercentage, "per46");
  padding: map-get($marginInPixel, "px10");
}
