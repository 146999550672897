@import "../../../../utils/styles/fonts.scss";
@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.customMessageRoot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: map-get($size, "px18");
  font-weight: map-get($weight, "bolder");
  color: $textValue;
  line-height: map-get($marginInPixel, "px24");
}

.root {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: $textValue;
  :global {
    .heading {
      font-weight: map-get($weight, "bolder");
      font-size: map-get($size, "px18");
      line-height: map-get($marginInPixel, "px24");
      margin-bottom: map-get($marginInPixel, "px8");
    }
    .message {
      line-height: map-get($marginInPixel, "px24");
    }
  }
}
