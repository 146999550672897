@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";
@import "../../../../utils/styles/fonts.scss";

.tabsContainer {
  height: map-get($marginInPixel, "px60");
  padding-top: map-get($margins, "px16");
}

.tabPanel {
  margin-top: 0;
  background-color: $white;
}

.dropDownContainer {
  display: flex;
  justify-content: flex-start;
  gap: map-get($marginInPixel, "px10");
}

.dropDown {
  display: flex;
  gap: map-get($marginInPixel, "px10");
}

.dropDown .getButton {
  width: map-get($marginInPixel, "px82");
}

.subTabContainer {
  position: relative;
}

.tooltipTitleContainer {
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px5");
  font-size: map-get($size , "px14");
}

.labelStyles {
  font-weight: map-get($weight , "bolder");
}

.valueStyles {
  font-weight: map-get($weight , "normal");
}

.actionsCell button {
  padding: 0;
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
}

.actionIcon {
  padding: map-get($marginInPixel, "px8");
  border-radius: map-get($marginInPixel, "px8");
  margin-top: 0;
}

.actionIcon:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
}
