@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.popupContainer .popupStyles {
  width: map-get($marginInPercentage, "per22");
  padding-bottom: map-get($marginInPixel, "px8");
}

.aboutCopyRight {
  font-size: map-get($size, "px12");
  line-height: map-get($marginInPixel, "px16");
  padding-top: map-get($marginInPixel, "px8");
  color: $textValue;
}

.popupContainer .popupStyles .closeIcon {
  position: absolute;
  top: map-get($marginInPixel, "px16");
  right: map-get($marginInPixel, "px16");
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
  border-radius: map-get($marginInPixel, "px8");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px8");
}

.aboutContent {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.aboutHeading {
  font-size: map-get($size, "px18");
  line-height: map-get($marginInPixel, "px24");
  font-weight: map-get($weight, "normal");
  padding-top: map-get($marginInPixel, "px12");
  padding-bottom: map-get($marginInPixel, "px12");
  color: $textValue;
}

.aboutVersion {
  font-size: map-get($size, "px14");
  line-height: map-get($marginInPixel, "px20");
  font-weight: map-get($weight, "normal");
  padding-bottom: map-get($marginInPixel, "px16");
  color: $textValue;
}

.aboutIcon {
    padding-top: map-get($marginInPixel, "px6");
}
