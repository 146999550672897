@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.fleetManagerWrapper {
  overflow-y: auto;
 height: calc(100vh - 48px);
  scroll-behavior: smooth;
}

.fleetManagerTitle {
  font-size: map-get($size, "px60");
  line-height: map-get($marginInPixel, "px66");
  color: $white;
}

.fleetManagerContainer {
  width: 100%;
  background-color: $textValue;
  background-image: url("../../../public/assets/images/background.svg");
  background-position: right;
  background-repeat: no-repeat;
  margin: auto;
  padding: map-get($marginInPercentage, "per5");
  padding-left: map-get($marginInPercentage, "per8o33");
}
.fleetManagerCont {
  color: $white;
  display: grid;
}
.fleetManagerDesc {
  font-size: map-get($size, "px22");
  width: map-get($marginInPercentage, "per88");
  max-width:  map-get($marginInPixel, "px890");
  padding-top: map-get($marginInPixel, "px36");
  padding-bottom: map-get($marginInPixel, "px36");
  line-height: map-get($marginInPixel, "px28");
}
.fleetManagerContainer > button {
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px20");
  border-radius: map-get($marginInPixel, "px3");
  text-transform: inherit;
}
.fleetManagerHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.fleetManagerHeader > ul {
  width: 100%;
  display: flex;
  background: $white;
  justify-content: center;
  margin: 0;
}
.fleetManagerHeader > ul > li {
  list-style-type: none;
  font-size: map-get($size, "px18");
  padding: map-get($marginInPixel, "px20");
}
.fleetManagerHeader > ul > li > a {
  text-decoration: none;
  color: $black;
}

.activeLink {
  border-bottom: map-get($marginInPixel, "px4") solid $activeUserMenu;
  color: $black;
}
.solutionSection {
  background-color: $littlegray;
}

.solutionIconLabel {
  display: flex;
  justify-content: left;
}

.solutionsDesc {
  padding-left: map-get($marginInPercentage, "per8o33");
  padding-bottom: map-get($marginInPixel, "px48");
  padding-top: map-get($marginInPixel, "px48");
  padding-right: map-get($marginInPercentage, "per8o33");
  color: $textValue;
}

.solutionsDescText {
  margin-top: map-get($marginInPixel, "px16");
}

.solutionDescription {
  display: grid;
  font-size: map-get($size, "px18");
  line-height: map-get($marginInPixel, "px24");
  height: map-get($marginInPixel, "px110");
  align-items: center;
}
.solutionsGrid {
  width: map-get($marginInPixel, "px201");
  text-align: left;
  display: flex;
  flex-direction: column;
}
.solutionsGrid > span {
  line-height: 28px;
  font-size: map-get($size, "px20");
}

.solutionsGridSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: revert;
  padding-bottom: map-get($marginInPercentage, "per2");
  padding-top: map-get($marginInPixel, "px36");
  padding-right: map-get($marginInPercentage, "per2");
}
.solutionIcon {
  font-size: map-get($size, "px88");
  line-height: map-get($marginInPixel, "px80");
  font-weight: bold;
  width: 32px;
  height: 32px;
}

.solutionsGrid > p {
  font-size: map-get($size, "px20");
  text-align: left;
  line-height: map-get($marginInPixel, "px28");
}

.featuresCont {
  padding-left: map-get($marginInPercentage, "per8o33");
  padding-right: map-get($marginInPercentage, "per8o33");
  padding-bottom: map-get($marginInPercentage, "per3");
  padding-top: map-get($marginInPercentage, "per3");
  background-color: $white;
}

.featuresGrid {
  width: map-get($marginInPercentage, "per25");
  margin-right: map-get($marginInPixel, "px48");
}

.featuresGridSection {
  width: 100%;
  display: flex;
  flex-direction: revert;
}
.featuresTitle {
  font-size: map-get($size, "px30");
  line-height: map-get($marginInPixel, "px36");
}

.subTitleIcon {
  height: map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px24");
  margin-bottom: map-get($marginInPixel, "px16");
}

.subTitle {
  font-size: map-get($size, "px18");
  font-weight: map-get($weight, "bolder");
  margin-bottom: map-get($marginInPixel, "px8");
  line-height: map-get($marginInPixel, "px24");
}

.desc {
  font-size: map-get($size, "px18");
  line-height: map-get($marginInPixel, "px24");
}

.featuresTitle, .subTitle, .desc {
  color: $textValue;
}

.quickGuide {
  padding-left: map-get($marginInPercentage, "per8");
  padding-bottom: map-get($marginInPercentage, "per6");
  padding-top: map-get($marginInPercentage, "per8");
}
.contactDesc {
  background-color: $littlegray;
  padding-left: map-get($marginInPercentage, "per8o33");
  padding-top: map-get($marginInPixel, "px48");
  padding-bottom: map-get($marginInPixel, "px48");
}
.contactTitle {
  font-size: map-get($size, "px30");
  line-height: map-get($marginInPixel, "px36");
  margin-bottom: map-get($marginInPixel, "px24");
  color: $textValue;
}
.contactLabel {
  padding-left: map-get($marginInPixel, "px6");
  color: $textLabel;
}
.footer {
  background-color: $footerBackground;
  color: $textValue;
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px4")
    map-get($marginInPixel, "px4") map-get($marginInPixel, "px16");
  display: flex;
  align-items: center;
  height: 32px;
  justify-content: space-between;
  font-size: map-get($size, "px12");
  border-top: map-get($marginInPixel, "px1") solid $gridHR;
}

.footerLabel {
  padding-right: map-get($marginInPixel, "px24");
  padding-top: map-get($marginInPixel, "px12");
}

.backToTop {
  position: fixed;
  bottom: map-get($marginInPixel, "px40");
  right: 0;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 map-get($marginInPixel, "px10") map-get($marginInPixel, "px10") 0;
  border-radius: map-get($marginInPercentage, "per50");
  padding: map-get($marginInPixel, "px10");
  width: map-get($marginInPixel, "px40");
  height: map-get($marginInPixel, "px40");
  background-color: $backgroundColor;
}

.backIcon {
  width: map-get($marginInPixel, "px20");
}
.signButton{
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px10");
  width: fit-content;
  margin-right: 24px;
}
.buttonSection {
  display: flex;
}

.link {
  color: $purple;
  text-decoration: none;
  cursor: pointer;
  display: flex;
}

.quickGuideSection {
    align-items: center;
    display: flex;
    justify-content: left;
    flex-direction: row;
    padding-top: map-get($marginInPixel, "px36");
}
.quickStart{
  margin-left: map-get($marginInPixel, "px8");
  font-size: map-get($size, "px16");
  line-height: map-get($marginInPixel, "px24");
  color: $breadcrumbBg;

}
.createButton {
  color: $breadcrumbBg;
  border: 1px solid  $breadcrumbBg;
}
.manualIcon{
  padding-right: map-get($marginInPixel, "px6");
}
.contactNumber{
  display: flex;
}
.contactEmail{
  display: flex;
  margin-bottom: map-get($marginInPixel, "px8");
}
.contactEmail > img{
  width: map-get($marginInPixel, "px16");
}
.contactNumber > img{
  width: map-get($marginInPixel, "px16");
}
.signButton button{
  width: 153px;
}
.accountButton button{
  width: 153px;
}