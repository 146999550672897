@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.popupContainer .popupStyles {
  width: map-get($marginInPercentage, "per30");
  max-width: map-get($marginInPixel, "px432");
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px22") map-get($marginInPixel, "px24")
    map-get($marginInPixel, "px22");
}

.qrContainer {
  width: map-get($marginInPercentage, "per100");
  display: flex;
  justify-content: center;
}

.qrImage {
  width: map-get($marginInPixel, "px124");
}
