@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.tabPanel {
  margin-top: map-get($marginInPixel, "px12");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  background-color: $white;
  padding: map-get($margins, "px16");
  border-radius: map-get($marginInPixel, "px8");
}

.tabsContainer {
  height: map-get($marginInPixel, "px60");
  padding: map-get($margins, "px16") map-get($margins, "px16") 0 map-get($margins, "px16");
  border-radius: map-get($marginInPixel, "px8");
}

.root {
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  background-color: $white;
}

.root .tabsContainer {
  border-bottom: none;
}

.headSectioon {
  padding: 0 map-get($margins, "px16") 0 map-get($margins, "px16");
}

.descText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}