@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.icon {
  height: map-get($margins, "px9");
  margin-right: map-get($marginInPixel, "px10");
  margin-top: 0;
}

.timePickerWrapper {
  position: absolute;
  right: 0;
  top: map-get($marginInPixel, "px6");
}

.timePickerWrapper button {
  padding: 0;
  height: map-get($marginInPixel, "px20");
  width: map-get($marginInPixel, "px20");
  img {
    margin-top: 0;
  }
}

.timePickerWrapper button:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px4");
}

.timePickerWrapper button.timeActive {
  background-color: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px4");
}

.timePickerPopper {
  height: map-get($marginInPixel, "px150");
  overflow-y: auto;
  position: absolute;
  top: map-get($marginInPixel, "px36");
  background: map-get($dropdown, "background");
  width: map-get($marginInPixel, "px150");
  box-shadow: 0px 1px 5px 0px map-get($dropdown, "dropdownShadow");
  border-radius: map-get($marginInPixel, "px4");
  padding-top: map-get($marginInPixel, "px4");
  li {
    margin-left: map-get($marginInPixel, "px4");
    margin-right: map-get($marginInPixel, "px4");
  }
}

.checkboxStateIcons {
  margin-top: 0;
}