@import "../../../utils/styles/margins.scss";

.topSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

:global(.assetMutedBar) .propertiesTable {
  height: calc(100vh - 404px);
}

.propertiesTable {
  height: calc(100vh - 384px);
  overflow-y: auto;
  margin-top: 20px;
  :global {
    div.editLabelWrapper {
      display: flex;
      align-items: center;
      width: 90%;
      justify-content: space-between;
    }
    .editIcon {
      display: inline-block;
      height: unset;
    }
    .tableLoader {
      height: calc(100vh - 394px);
    }
  }
}

.loader {
  height: calc(100vh - 330px);
}

.searchField {
  width: map-get($marginInPercentage, "per16o5");
}
