@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.Icon {
  margin-right: map-get($marginInPixel, "px6");
}

.divider {
  width: map-get($marginInPixel, "px1");
  height: map-get($marginInPixel, "px24");
  background: $darkGrey;
  border-radius: 1px;
}
