@import "../../../utils/styles/margins.scss";

.topSection {
  display: flex;
  justify-content: flex-end;
  gap: map-get($marginInPixel, "px20");
}

:global(.assetMutedBar) .softwareTable {
    height: calc(100vh - 404px);
}

.softwareTable {
  height: calc(100vh - 384px);
  overflow-y: auto;
  margin-top: map-get($marginInPixel, "px20");
  :global {
    & .customTableContainer {
      height: inherit;
    }
    & .noRows .customTable {
      height: inherit;
    }
    & .tableLoader {
      height: calc(100vh - 420px);
    }
  }
}

.loader {
  height: calc(100vh - 329px);
}

.cancelDeployPopup {
  width: map-get($marginInPercentage, "per42");
}
