@import "../../../../../../utils/styles/margins.scss";
@import "../../../../../../utils/styles/colors.scss";

.actionsCell{
    align-items: center;
    display: flex;
    gap: map-get($marginInPercentage, "per19")
} 

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
}

.actionsCell button {
  padding: map-get($marginInPixel, "px8");
  height: map-get($marginInPixel, "px32");
  width: map-get($marginInPixel, "px32");
}

.actionsCell button:hover {
  background-color: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.actionsCell button:active {
  background-color: map-get($buttons, "contentPrimaryfocus");
}