@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";

.colorIndicator {
  background-color: $success;
  border-radius: 2px;
  height: 14px;
  margin-right: map-get($margins, "px8");
  width: 14px;
}

.rootIndicator {
  align-items: center;
  display: flex;
}
