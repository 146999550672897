@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.editableLabel:hover {
  color: $textValue;
  font-weight: map-get($weight , "bolder");
}

.textEllipsis {
  max-width: map-get($marginInPixel , "px320");
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editableLabel, .label {
  font-weight: map-get($weight , "bolder");
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inherit;
  padding-right: map-get($marginInPixel, "px6");
}

.label label {
  cursor: inherit;
  display: block;
}

.editableLabel label {
  cursor: pointer;
}

.editIcon {
  display: none;
  height: map-get($marginInPixel , "px16");
  background: none;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  cursor: pointer;
}

.edit {
  margin-top: 0;
}

.editableLabel:hover + .editIcon {
  display: inline-block;
}

.editIcon:hover {
  display: inline-block;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  padding: 0;
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel , "px8");
}
.editIcon:focus {
  display: inline-block;
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  padding: 0;
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel , "px8");
}
.marginText{
  margin-left: map-get($marginInPixel, "px4");
}