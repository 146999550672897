@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.assetsFaults {
  background-color: $white;
  position: relative;
  padding: map-get($marginInPixel, "px16");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
}

.filtersSection {
  display: flex;
  justify-content: space-between;
  height: map-get($marginInPixel, "px34");
  width: map-get($marginInPercentage, "per100");
}
.pageContentLeft {
  width: map-get($marginInPercentage, "per40");
  align-items: center;
  display: flex;
}

.pageContentRight {
  width: map-get($marginInPercentage, "per60");
  align-items: center;
  display: flex;
  gap: map-get($marginInPercentage, "per1o2");
  justify-content: flex-end;
}

.iconButton,
.pageContentRight .filterButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px5");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
}

.pageContentRight .filterButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.pageContentRight .filterButton:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.assetFaultsSummary {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 320px);
  overflow-y: auto;
}
.loading {
  height: calc(100vh - 276px);
}

.assetsStaticCards {
  display: flex;
  height: map-get($marginInPixel, "px120");
  justify-content: space-between;
  margin-bottom: map-get($marginInPixel, "px16");
  gap: map-get($marginInPixel, "px16");
}
.assetsStaticCards > div,
.assetsStaticCards > span {
  flex: 1;
  padding: map-get($marginInPixel, "px12") map-get($marginInPercentage, "per2");
}

.assetsStaticCards > .divider {
  width: map-get($marginInPixel, "px1");
}

.pageContentRight .filterButton {
  img {
    margin-top: 0;
  }
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.overlay {
  height: calc(100vh - 237px) !important;
}

.pageContentRight .exportButton {
  min-width: map-get($marginInPixel, "px84");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px8");
}

.pageContentRight .ackBtn {
  min-width: map-get($marginInPixel, "px130");
  padding: map-get($marginInPixel, "px4") map-get($marginInPixel, "px8");
}

.datePicker {
  max-width: map-get($marginInPercentage, "per40");
}

.searchField {
  width: map-get($marginInPercentage, "per24");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
}

.noFaults {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 26px;
}

.faultsPiChartCard {
  background-color: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  width: map-get($marginInPercentage, "per24");
  height: map-get($marginInPixel, "px120");
  padding: map-get($marginInPixel, "px12")
    map-get($marginInPercentage, "per1o25");
  display: flex;
  justify-content: center;
  align-items: center;
  gap: map-get($marginInPercentage, "per8");
  border-radius: map-get($marginInPixel, "px8");
}

.selectedAssets {
  display: inline-block;
  text-align: center;
}


.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}

.contentLoading {
  height: map-get($marginInPixel, "px275");
}
