@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";

.errorIcon {
  height: map-get($marginInPixel, "px16");
}

.inputField {
  align-items: center;
  background: map-get($inputValidation, "typingBackground");
  border-bottom: map-get($marginInPixel, "px1") solid map-get($inputValidation, "typingBorder");
  display: flex;
  height: map-get($marginInPixel, "px28");
  width: map-get($marginInPercentage, "per100");
}

.invalidInput {
  align-items: center;
  background: map-get($inputValidation, "errorBackground");
  border-bottom: map-get($marginInPixel, "px1") solid map-get($inputValidation, "errorBorder");
  display: flex;
  height: map-get($marginInPixel, "px28");
  width: map-get($marginInPercentage, "per100");
}

.label {
  cursor: pointer;
  margin-left: map-get($marginInPercentage, "per2");
}

.tooltipTitle {
  white-space: pre-line;
}

.loadingIcon {
  margin-right: map-get($marginInPixel, "px7");
}

.icon {
  height: map-get($marginInPixel, "px16");
}

.infoIcon {
  padding: map-get($marginInPixel, "px8");
}

.saveIcon {
  height: map-get($marginInPixel, "px11");
  width: map-get($marginInPixel, "px15");
}

.cancelIcon {
  height: map-get($marginInPixel, "px14");
  width: map-get($marginInPixel, "px14");
}
