@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.message {
  display: block;
  font-size: map-get($marginInPixel, "px22");
  margin-top: map-get($marginInPixel, "px24");
}

.noFleetsDetails{
  height: calc(100vh - 88px);
  padding-left: map-get($marginInPixel, "px120");
  padding-top: map-get($marginInPixel, "px63");
  background: $white;
}

.title {
  font-size: map-get($marginInPixel, "px40");
}
