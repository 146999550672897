@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/constants.scss";
@import "../../utils/styles/mixins.scss";

.sideBarCollapse {
  width: $sideNavigationCollapseWidth;
}

.sideBarExpand {
  width: $sideNavigationExpandWidth;
  position: absolute;
  z-index: 9999;
}

.sidebar {
  background-color: map-get($sideNavigation, "rootBackground");
  border-right: 1px solid $borderGrey;
  height: calc(100vh - 82px);
  padding: map-get($marginInPixel, "px8") map-get($marginInPixel, "px4");
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px4");
  @include unSelectableText;
}
