@import "../../../../../utils/styles/margins.scss";
@import "../../../../../utils/styles/colors.scss";

.icon {
  margin-right: map-get($marginInPercentage, "per8");
}

.fleetButton {
  width: map-get($marginInPixel, "px32");
}

.disabledMenu {
  width: 100%;
  opacity: 0.5;
}

:global(.FleetMenu) {
  .active {
    border-radius: map-get($marginInPixel, "px8");
    background-color: $fleetMenuActive;
    padding: map-get($marginInPixel, "px8");
    width: map-get($marginInPixel, "px32");
  }
  .menuItem:hover {
    background-color: $selectedBackground;
  }
  .fleetButton:hover {
    border-radius: map-get($marginInPixel, "px8");
    background-color: $borderGrey;
    padding: map-get($marginInPixel, "px8");
    width: map-get($marginInPixel, "px32");
  }
}
