@import "../../utils/styles/margins.scss";

.summaryHeader{
  margin: -16px -16px 0 -16px;
  background-color: white;
}

.popupStyle {
  z-index: 100;
  width: map-get($marginInPercentage, "per35");
  position: fixed;
}