@import "../../utils/styles/colors.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.accordionLayout {
  border: map-get($marginInPixel, "px1") solid $border;
  border-radius: map-get($marginInPixel, "px3");
  box-shadow: none;
  margin: 0;
}

.unAuthorizedSelectCheckbox {
  :global{
    & .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      right: 15px;
    }
  }
}

.accordionDetailsStyles {
  border-top: map-get($marginInPixel, "px1") solid $border;
  border-radius: map-get($marginInPixel, "px3");
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.card {
  align-items: center;
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.distributionBar {
  width: map-get($marginInPercentage, "per33");
}

.distributionPanel {
  display: flex;
  justify-content: space-between;
  width: map-get($marginInPercentage, "per55o5");
}

.distributionPanel > div {
  margin-left: map-get($marginInPercentage, "per10");
}

.distributionPanel > div:first-child {
  margin-left: 0;
}

.expandIcon {
  margin-right: map-get($marginInPercentage, "per2");
  margin-top: map-get($marginInPixel, "px2");
}

.icon {
  align-items: center;
  height: map-get($marginInPixel, "px16");
  margin-right: map-get($marginInPixel, "px16");
  text-align: center;
}

.iconLabel {
  align-items: center;
  display: flex;
  font-weight: map-get($weight, "bolder");
  line-height: map-get($marginInPixel, "px22");
  margin-right: map-get($marginInPercentage, "per5");
  width: map-get($marginInPercentage, "per25");
}

.iconLabel > span {
  margin-right: map-get($marginInPixel, "px16");
}

@media (min-width: 1400px) {
  .iconLabel {
    width: map-get($marginInPercentage, "per30");
  }
  .distributionPanel {
    width: map-get($marginInPercentage, "per50o5");
  }
}
