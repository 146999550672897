@import "../../../../../utils/styles/fonts.scss";
@import "../../../../../utils/styles/margins.scss";
@import "../../../../../utils/styles/colors.scss";

.accordionLayout {
  margin: 0;
  .accordionContainer {
    background-color: map-get($siteNavigator, "background");
    display: flex;
    flex-direction: column;
    gap: map-get($marginInPixel, "px4");
    margin: 0 !important;
  }
}

.accordionSummary:global(.SITE).noExpand {
  margin-left: map-get($marginInPixel, "px24");
}

.accordionSummary:global(.FLEET).noExpand {
  margin-left: map-get($marginInPixel, "px24");
}

.accordionSummary > div > .expandIcon {
  padding: 0;
}

.accordionSummary > div > .expandIcon:hover {
  background: inherit;
}

.accordionSummary :global(.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters) {
  width: map-get($marginInPercentage, "per90");
}

.accordionSummary:hover {
  background: map-get($siteNavigator, "hoverBg");
  border-radius: map-get($marginInPixel, "px4");
}

.accordionSummary:active {
  background: map-get($siteNavigator, "activeBg");
  border-radius: map-get($marginInPixel, "px4");
}

.accordionLabel {
  display: flex;
  align-items: center;
  color: $textValue;
  text-decoration: none;
  width: map-get($marginInPercentage, "per100");
  line-height: map-get($marginInPixel, "px24");
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelStyles {
  cursor: pointer;
}

.icon {
  height: map-get($marginInPixel, "px16");
  width: map-get($marginInPixel, "px16");
  margin-right: map-get($marginInPixel, "px8");
}

.expanded {
  font-weight: map-get($weight, "bold");
}

.active {
  color: $activeUserMenu;
  font-weight: map-get($weight, "bolder");
}

.activeChild {
  color: $label;
  font-weight: map-get($weight, "bolder");
}

.tooltip {
  max-width: map-get($marginInPixel, "px210");
}

:global(.SiteNavigator > .MuiTooltip-tooltipPlacementBottom) {
  margin-top: map-get($marginInPixel, "px12") !important;
}
