@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/colors.scss";

.configTitle {
  color: $primary;
  font-size: map-get($size, "px24");
  line-height: map-get($margins, "px28");
  margin-right: map-get($marginInPixel, "px16");
  width: map-get($marginInPercentage, "per50");
  display: flex;
  align-items: center;
}

.configHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deployButton button {
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px124");
  height: map-get($marginInPixel, "px34");
}

.toggleButton img {
  min-height: auto;
}

.configFleets {
  background-color: $white;
  height: calc(100vh - 155px);
  margin-top: map-get($marginInPixel, "px16");
  position: relative;
  padding: map-get($marginInPixel, "px16");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
}

.filtersSection {
  display: flex;
  height: 34px;
}

.fleetsSummary {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.pageContentRight {
  display: flex;
  margin-left: auto;
}

.pageContentRight > button {
  margin-right: map-get($marginInPercentage, "per4");
}

.pageContentLeft {
  display: flex;
  width: map-get($marginInPercentage, "per90");
  align-items: center;
  white-space: nowrap;
}

.filterBlock {
  display: flex;
  width: 100%;
  margin-left: map-get($marginInPercentage, "per1");
}

.confirmationPopUp {
  width: map-get($marginInPercentage, "per42");
}

.assetsSearchTable {
  height: calc(100vh - 220px) !important;
  margin-top: map-get($margins, "px8");
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
  }
}
.searchResultsText {
  width: map-get($marginInPercentage, "per50");
  align-self: center;
  text-align: right;
  margin-right: map-get($margins, "px5");
}
.searchErrorContainer {
  height: calc(100vh - 230px);
}
.deploymentTable {
  :global {
    & .customTable .internalSelectAll {
      margin-left: map-get($margins, "px8");
    }
  }
}
.deployButton {
  width: map-get($marginInPercentage, "per50");
  text-align: right;
}

.pageContentRight .filterButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px5");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
  img {
    margin-top: 0;
  }
}

.pageContentRight .filterButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.pageContentRight .filterButton:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.deployButtonCont {
  background-color: map-get($buttons, "primary");
  border-radius: map-get($marginInPixel, "px10");
}

.deployButtonCont button {
  width: 124px;
}

.backButton {
  margin-right: map-get($marginInPixel, "px16") !important;
}

.contentLoading {
  height: map-get($marginInPixel, "px275");
}
