.customCheckbox {
  color: #d7d8db !important;
  width: 16px;
  height: 16px;
  background: #3132350A !important;
  padding: 0px !important;
  border-radius: 25% !important;
}

.customCheckbox.Mui-checked,.customCheckbox.MuiCheckbox-indeterminate{
  color: #63666a !important;
  }
  .customCheckbox:not(.Mui-checked,.MuiCheckbox-indeterminate) svg{
    border: 1px solid #D7D8DB !important;
    border-radius: 4px !important;
    height: 16px;
    width: 16px;
    color: transparent !important;
  }