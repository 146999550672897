@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.muteIcon {
  margin-top: 0;
  height: map-get($marginInPixel, "px24");
  width: map-get($marginInPixel, "px24");
}

.labelSwitch {
  display: inline-flex;
  align-items: center;
  margin-right: map-get($marginInPixel, "px4");
  :global {
    & .iconButtonToggle {
      padding: 0;
    }
  }
}

.label {
  margin-right: map-get($marginInPixel, "px10");
  line-height: map-get($marginInPixel, "px24");
  color: $muteLabel
}

.checkedSwitch {
  min-height: map-get($marginInPixel, "px22");
  margin-top: 0;
}
