@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/colors.scss";

.container {
  color: inherit;
  :global {
    & .deploy {
      margin-top: map-get($marginInPixel, "px16");
    }
    & .inputLable {
      margin-top: map-get($marginInPixel, "px18");
      margin-bottom: map-get($marginInPixel, "px8");
    }
    & #deployNotes {
      margin-top: map-get($marginInPixel, "px8");
    }
    & .scheduleDeploy {
      display: flex;
      margin-top: map-get($marginInPixel, "px16");
      gap: map-get($marginInPixel, "px16");
    }
  }
}

.notesContainer {
  margin-top: map-get($marginInPixel, "px16");
}

.helperText {
  font-size: map-get($size, "px14");
  color: $textLabel;
  line-height: map-get($marginInPixel, "px20");
  margin-top: map-get($marginInPixel, "px4");
  width: map-get($marginInPixel, "px300");
}

.confirmText {
  max-width: map-get($marginInPixel, "px432");
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
