@import "../../../utils/styles/fonts.scss";
@import "../../../utils/styles/margins.scss";
@import "../../../utils/styles/colors.scss";

.softwareUpdateStatisticCard {
  display: flex;
  height: map-get($marginInPixel, "px120");
  justify-content: space-between;
  margin-bottom: map-get($marginInPixel, "px16");
  gap: map-get($marginInPixel, "px16");
}

.softwareUpdateStatisticCard > div,
.softwareUpdateStatisticCard > span {
  flex: 1;
  padding: map-get($marginInPixel, "px12") map-get($marginInPercentage, "per2");
}

.softwareUpdateStatisticCard > .divider {
  width: map-get($marginInPixel, "px1");
}

.softwareDataSection {
  background-color: $white;
  position: relative;
  padding: map-get($marginInPixel, "px16");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  height: calc(100vh - 238px);
  overflow: hidden;
}

.softwareSummary {
  height: calc(100vh - 308px);
  overflow-y: auto;
}

.topSection {
  display: flex;
  height: map-get($marginInPixel, "px34");
  margin-bottom: map-get($marginInPixel, "px16");
}

.leftSection {
  display: flex;
  width: map-get($marginInPercentage, "per40");
  align-items: center;
  white-space: nowrap;
}

.filterBlock {
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.rightSection {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  width: map-get($marginInPercentage, "per50");
}

.datePicker {
  max-width: map-get($marginInPercentage, "per50");
}

.searchField {
  width: map-get($marginInPercentage, "per30");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  margin-left: map-get($marginInPixel, "px12");
}

.rightSection .filterButton {
  margin-right: map-get($marginInPixel, "px8");
  img {
    margin-top: 0;
  }
}

.iconButton,
.rightSection .filterButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px5");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
}

.rightSection .filterButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.rightSection .filterButton:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.accordionGroup {
  height: calc(100vh - 308px);
  overflow-y: auto;
}

.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}

.overlay {
  height: calc(100vh - 240px);
}

.contentLoading {
  height: map-get($marginInPixel, "px160");
}
