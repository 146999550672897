@import "../../../../utils/styles/fonts.scss";
@import "../../../../utils/styles/margins.scss";
@import "../../../../utils/styles/colors.scss";

.statisticsSection {
  height: 120px;
  margin-bottom: map-get($marginInPixel, "px16");
  display: flex;
  gap: map-get($marginInPixel, "px16");
  & > div {
    flex: 1;
  }
}

.statisticsDataCard {
  width: map-get($marginInPercentage, "per33");
  height: 120px;
  border: map-get($marginInPixel, "px1") solid $border;
  background-color: #ffffff;
  padding: map-get($marginInPixel, "px15") map-get($marginInPercentage, "per2");
}

.configAssets {
  background-color: $white;
  height: calc(100vh - 328px);
  position: relative;
}

.filtersSection {
  display: flex;
  height: 34px;
}

.pageContentRight {
  width: map-get($marginInPercentage, "per58");
  align-items: center;
  display: flex;
  gap: map-get($marginInPercentage, "per1o2");
  justify-content: flex-end;
}

.assetsSummary {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.statisticsData {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: map-get($marginInPixel, "px4");
  width: map-get($marginInPercentage, "per33");
  height: map-get($marginInPixel, "px110");
  background: $white;
  border: map-get($marginInPixel, "px1") solid $border;
  border-radius: map-get($marginInPixel, "px1");
  text-align: center;
}

.configStatistics {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: map-get($marginInPixel, "px16");
  height: map-get($marginInPixel, "px110");
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.actionsIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 12px 12px 0px;
  gap: 10px;
  isolation: isolate;
  width: 75px;
}

.pageContentLeft {
  width: map-get($marginInPercentage, "per42");
  align-items: center;
  display: flex;
}

.filterBlock {
  display: flex;
  align-items: center;
  width: map-get($marginInPercentage, "per100");
  margin-left: map-get($marginInPercentage, "per1");
}

.confirmationPopUp {
  width: map-get($marginInPercentage, "per42");
}

.overlay {
  height: calc(100vh - 240px) !important;
}

.assetsSearchTable {
  height: calc(100vh - 314px) !important;
  margin-top: 8px;
}
.searchErrorContainer {
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 334px);
  overflow-y: auto;
}

.searchResultsText {
  display: inline-block;
  text-align: center;
}

.filterIcon {
  margin-top: 0;
}

.noConfigFileContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .heading {
    font-weight: map-get($weight, "bolder");
    font-size: map-get($size, "px20");
    line-height: 26px;
  }
  .message {
    color: #525457;
  }
}

.pageContentRight .filterButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: map-get($marginInPixel, "px5");
  width: map-get($marginInPixel, "px32");
  height: map-get($marginInPixel, "px32");
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: none;
  }
  img {
    margin-top: 0;
  }
}

.pageContentRight .filterButton:hover {
  background: map-get($buttons, "contentPrimaryhover");
  border-radius: map-get($marginInPixel, "px8");
}

.pageContentRight .filterButton:active {
  background: map-get($buttons, "contentPrimaryfocus");
  border-radius: map-get($marginInPixel, "px8");
}

.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}

.contentLoading {
  height: map-get($marginInPixel, "px160");
}

.noConfig {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 26px;
}

.configContainer {
  background-color: $white;
  position: relative;
  margin-top: map-get($marginInPixel, "px16");
  height: calc(100vh - 368px);
  overflow-y: auto;
}

.datePicker {
  max-width: map-get($marginInPercentage, "per44");
}

.searchField {
  width: map-get($marginInPercentage, "per28");
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
}
