@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.messageContainer {
  margin: map-get($marginInPixel, "px14") 0 map-get($marginInPixel, "px16") 0;
  font-weight: map-get($weight, "normal");
  color: $textValue;
  div {
    line-height: map-get($marginInPixel, "px20");
  }
}

.popupStyles {
  width: map-get($marginInPercentage, "per30");
}

.popupHeader {
  display: block;
  margin-top: map-get($marginInPixel, "px34");
  margin-bottom: map-get($marginInPixel, "px8");
  color: $muteLabel;
}

.contentSection {
  display: flex;
  flex-direction: column;
  border-bottom: map-get($marginInPixel, "px1") solid $borderGrey;
}

.contentborder {
  display: flex;
  flex-direction: column;
  margin-right: map-get($marginInPixel, "px10");
}

.sectionName {
  color: $textValue;
  line-height: map-get($marginInPixel, "px24");
  font-weight: map-get($weight, "bolder");
  padding: map-get($marginInPixel, "px10") map-get($marginInPixel, "px16") 0 map-get($marginInPixel, "px16");
}

.packageName {
  max-width: map-get($marginInPercentage, "per85");
}

.packageVersion {
  white-space: nowrap;
}

.softwaredetails {
  height: map-get($marginInPixel, "px300");
  overflow-y: auto;
  margin-bottom: map-get($marginInPixel, "px24");
  color: $textValue;
}

.packageDetailData {
  display: flex;
  height: map-get($marginInPixel, "px32");
  font-weight: map-get($weight, "bold");
  padding-top: map-get($marginInPixel, "px8");
}

// .textEllipsis {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.sectionFileSize {
  display: block;
  color: $helperText;
  font-weight: map-get($weight, "normal");
  font-size: map-get($size, "px14");
  line-height: map-get($marginInPixel, "px16");
  padding-bottom: map-get($marginInPixel, "px10");
}
