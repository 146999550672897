@import "../../../../utils/styles/colors.scss";
@import "../../../../utils/styles/margins.scss";

.notInSubFleetContainer {
  height: 250px;
  overflow: auto;
  :global {
    & .customTableContainer.noRows {
      .customTable {
        height: inherit;
      }
    }
  }
}

.notInSubFleetCardContainer {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  overflow: auto;
  padding: 8px 0;
}
.notInSubFleetCardContainer > div {
  width: calc(25% - 7px);
}

.draggingAssetPlaceHolder {
  transform: none !important;
  width: 22%;
  opacity: 10%;
  pointer-events: none;
}

.accordionCustomClass {
  :global {
    & .collapsed.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4;
    }
    & .accordionCheckBoxClass.MuiAccordionSummary-root {
      border: 1px solid #d1d2d4 !important;
    }
    & .MuiAccordionSummary-root {
      border-radius: 8px;
    }
  }
}
