@import "../../../../utils/styles/margins.scss";

.reportsSWPackageTable {
  height: calc(100vh - 330px);
  margin-top: map-get($margins, "px16");
  :global {
    & .noRows .customTable {
      height: inherit;
    }
  }
}
