@import "../../../../utils/styles/fonts.scss";
@import "../../../../utils/styles/margins.scss";

.root {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  :global {
    .message {
      font-weight: map-get($weight, "bolder");
      font-size: map-get($size, "px16");
      line-height: map-get($marginInPixel, "px22");
      margin-bottom: map-get($marginInPixel, "px12");
    }
  }
}
