@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";

.count {
  color: $textValue;
  font-size: map-get($size, "px52");
  width: map-get($marginInPercentage, "per100");
  line-height: map-get($marginInPixel, "px60");
}

.label {
  color: $textLabel;
  width: map-get($marginInPercentage, "per100");
  white-space: nowrap;
  line-height: map-get($marginInPixel, "px20");
}
.muteSection {
  color: $textValue;
  width: map-get($marginInPercentage, "per100");
  white-space: nowrap;
  line-height: map-get($marginInPixel, "px20");
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.mutedIcon {
  padding-right: map-get($marginInPixel, "px6");
  margin-top: 0;
}
.mutedText {
  line-height: map-get($marginInPixel, "px16");
  font-size: map-get($marginInPixel, "px12");
}
