$alertsSeverity: (
  critical: #a32940,
  low: #edc50c,
  moderate: #f5a147,
);

$assetStatus: (
  default: #b8b9bb,
  available: #68b76f,
  inUse: #51bddf,
  offline: #D1D2D4,
);

$background: #fafbfd;
$backgroundColor: #f8f8f8;

$bars: (
  background: #ECEDEE,
  blue: #2e627f,
  green: #68b76f,
  grey: #b8b9bb,
  lightBlue: #5096bf,
  lightGray: #929498,
  orange: #b05f09,
  paleBlue: #a5c9de,
  red: #a80925,
  skyBlue: #90d5ea,
  yellow: #bf9f0a,
);

$chips: (
  default: #b8b9bb,
  error: #D1D2D4,
  primary: #4B74A3,
  secondary: #8FBF9D,
  warning: #DBA4A4,
  label: #2b2d2e,
  failed: #A32940,
  queued: #9FBADA
);

$datePicker: (
  label: #63666a,
  outline: #80878e,
  hoverBg: #1E1F201F,
  textFieldBackground: #ECEDEE,
  border: #d7d8db,
  selected: #746A95,
  selectedBorder: #7252BC,
  errorBorder: #a32940,
  text: #F9F9FA,
  dateLabel: #666b70,
  textValue: #313235,
  activeBg: #1E1F203D,
  disabledText: #3132355c,
  overlayShadow: #1E1F2052,
  activeBackground: #31323529
);

$defaultBars: (
  inProgress: #7f8185,
  pending: #b8b9bb,
  cancel: #d7d8db,
);

$purple: #7252bc;
$black: #333;
$blue: #02536c;
$border: #ececf0;
$borderColor: #c5c7c9;
$lightYellow: #f5cc0e;
$pureBlack: #000000;

$boxShadow: #c0c0c0;
$breadcrumb: #313235;
$breadcrumbBg: #f9f9fa;
$tooltipBoxShadow: #1E1F2052;

$buttons: (
  label: #f9f9fa,
  primary: #7252bc,
  secondary: #02536c,
  disabled: #b8b9bb,
  disabledBackground: #ececf0,
  chipLabel: #2b2d2e,
  outlinedDisabled: #c5c7c9,
  textPrimaryBackground: #7252bc1f,
  focus: #7252bc3d,
  contentPrimaryhover: #1e1f201f,
  contentPrimaryfocus: #1e1f203d,
  outlinedSkyBlue: #B39CF1,
);

$cardLabel: #18181a;
$danger: #a80925;
$helperText: #666b70;
$darkGrey: #d7d8db;
$borderGrey: #d1d2d4;
$disabled: #b8b9bb;
$disabledBackground: #f4f5f8;
$filterItemBackground: #ecedee;
$fleetMenuActive: #1e1f203d;
$textFieldOutline: #80878e;
$darkPurple: #373149;
$cardHover: #1e1f2014;
$editIconHover: #7252bc1f;
$littlegray: #E7E5F066;
$dropdown: (
  background: #ecedee,
  border: #80878e,
  selectAllText: #313235,
  disabledBackground: #f4f5f8,
  inputText: #313235,
  labelText: #55595E,
  labelTextDisabled: #b8b9bb,
  selectedBackground: #746a95,
  userProfileSelectedBackground: #c5bfd9,
  selectedText: #f9f9fa,
  required: #A32940,
  backgroundGray: #ecedee,
  backgroundLightGray: #f9f9fa,
  lightDisabledBackground: #3132350a,
  dropdownShadow: #1e1f2052,
  dropdownHover: #1E1F201F
);

$filterButtonColor: #037aa6;
$gray: #f1f1f1;
$selectedBackground: #c5bfd9;
$lightDisabledBackground: #3132350a;

$icons: (
  alert: #df4661,
);

$input: (
  background: #ecedee,
  border: #80878e,
);

$inputValidation: (
  errorBackground: #fceef0,
  errorBorder: #df4661,
  typingBackground: #e7e5f0,
  typingBorder: #7252bc,
);

$label: #2b2d2e;
$lightGray: #e7e5f0;
$primary: #2b2d2e;
$headerBackground: #f9f9fa;
$footerBackground: #f9f9fa;
$red: #df4661;
$secondary: #7252bc;
$activeUserMenu: #7252bc;
$activeUserBackground: #31323529;
$noResultText: #313235;
$badgeText: #f9f9fa;
$noteSendBg: #b6becc;
$noteReceiveBg: #ecedee;
$inputFieldHover: #1E1F201F;

$searchBar: (
  focused: #7252bc,
  disabledBackground: #3132350a,
  inputBackgroundHover: #1e1f201f,
);

$sideNavigation: (
  title: #313235,
  disabledText: #3132355c,
  menuItemActiveBorder: #746a95,
  menuItemBackground: #c5bfd9,
  rootBackground: #f9f9fa,
);

$searchPlaceholder: #666b70;
$success: #68b76f;
$textLabel: #55595e;
$labelText: #525457;
$textValue: #313235;
$tooltip: #f9f9fa;
$toggleBackground: #31323529;
$updatesStatus: (
  inProgress: #4b74a3,
  pending: #9fbada,
  cancel: #878C92,
);

$configsStatus: (
  inInstall: #4B74A3,
  inDownload: #9FBADA,
  failed: #878C92,
);

$userSettingsMenuBackground: #ffffff;
$userSettingsMenuBackgroundHover: #d7d8db;
$white: #ffffff;
$gridHR: #d1d2d4;

$tableActiveRowBackground: #e7e5f0;

$createUpdateOverlayBackground: #333;
$createUpdateOverlayInfoContainer: #55595e;
$createUpdateOverlayErrorInfo: #a32940;
$requiredField: #8C2035;

$createEditOverlay: (
  outline: #7252bc,
  errorBackground: #fceef0,
  errorBorder: #a32940,
  inputBackground: #ecedee,
);

$toaster: (
  success: #8fbf9d,
  info: #b6becc,
  low: #edc50c,
  medium: #f5a147,
  critical: #a32940,
  criticalText: #f9f9fa,
);

$siteNavigator: (
  background: #f9f9fa,
  hoverBg: #1e1f201f,
  activeBg: #1e1f203d,
  breadcrumbIconActive: #d6d2e4,
  breadcrumbIconOpen: #31323529,
);

$subTableHeaderBackground: #f3f3f4;
$expandedRowBorder: #80878e;
$expandedRowBorderLite: #80878E99;

$dragAssetBoxShadow: #1e1f2047;
$tableSelectedBorder: #746a95;

$toggleSwitch: (
  switchBackground: #746a95,
  switchThumb: #ecedee,
);

$muteLabel: #55595e;
$notificationBar: #edc50c;
$opacityForText: #3132355c;
$textColor: #B39CF1