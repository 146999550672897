@import "../../utils/styles/margins.scss";

.datePicker {
  display: flex;
  gap: map-get($marginInPixel, "px8");
  justify-content: center;
  align-items: center;
}

.separator {
  font-style: italic;
}
