@import "../../utils/styles/margins.scss";
@import "../../utils/styles/fonts.scss";

.icon {
  height: map-get($marginInPixel, "px32");
}

.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: map-get($marginInPixel, "px16");
  justify-content: center;
  margin: auto;
  height: inherit;
}

.boldTitle {
  font-weight: map-get($weight, "bolder");
}

