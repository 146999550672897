@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background: $white;
  flex-direction: column;
  border-radius: map-get($marginInPixel, "px8");
}

.loading {
  text-align: center;
}

.loadingIcon {
  height: map-get($marginInPixel , "px75")
}

.isOverladed{
  position: absolute;
  width: 100%;
  z-index: 999;
  opacity: 0.9;
}

.customLoader {
  width: map-get($marginInPixel, "px80");
  height: map-get($marginInPixel, "px80");
  border-radius: map-get($marginInPercentage, "per50");
  background: radial-gradient(farthest-side, #24006f 20%, #0000) top/5px 5px
      no-repeat,
    conic-gradient(
      #0000 30%,
      #24006f 0deg,
      rgba(239, 235, 255, 0) 90deg,
      rgba(239, 235, 255, 0.9) 133.12deg,
      #600acd 288.75deg,
      #480acd 331.88deg,
      #24006f 360deg
    );
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  animation: s3 1s linear infinite;
}

@-webkit-keyframes s3{
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes s3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingText {
  font-size: map-get($marginInPixel, "px16");
  line-height: map-get($marginInPixel, "px22");
  font-weight: 400;
  padding-bottom: map-get($marginInPixel, "px8");
}
.loadingDesc {
  font-size: map-get($marginInPixel, "px14");
  line-height: map-get($marginInPixel, "px18");
}
