@import "../../../utils/styles/colors.scss";
@import "../../../utils/styles/margins.scss";


.instructionText {
  font-size: map-get($marginInPixel, "px14");
  color: $textLabel;
  line-height: map-get($marginInPixel, "px20");
  margin-top: map-get($marginInPixel, "px4");
  white-space: nowrap;
}

.label {
  font-weight: 400;
  line-height: map-get($marginInPixel, "px22");
  color: $labelText;
  padding: map-get($marginInPixel, "px6") 0;
}

.inputFormControl {
  border: map-get($marginInPixel, "px1") solid #80878e;
  border-radius: map-get($marginInPixel, "px4");
}

.disabledInputFormControl {
  border-radius: map-get($marginInPixel, "px4");
}

.disabledLabel {
  color: $disabled;
}

.labelContainer {
  display: flex;
  width: map-get($marginInPercentage, "per100");
}

.labelElements {
  text-align: right;
}

.errorMessage {
  color: $createUpdateOverlayErrorInfo;
  font-size: map-get($marginInPixel, "px14");
  line-height: map-get($marginInPixel, "px20");
  width: map-get($marginInPixel, "px275");
  margin-top: map-get($marginInPixel, "px4");
  height: map-get($marginInPixel, "px20");
}
.required {
  color: $requiredField;
}

.infoStyle {
  width: map-get($marginInPixel, "px16");
}
