@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";


.accordionLayout {
    border: map-get($marginInPixel, "px1") solid $border;
    border-radius: map-get($marginInPixel, "px3");
    box-shadow: none;
    margin: 0;
}
  
.accordionDetailsStyles {
    border-radius: map-get($marginInPixel, "px3");
    :global {
        & .customTable .selectAllWithColorBar {
            margin-left: map-get($margins, "px8");
        }
    }
}

.accordionDetailsStyles:global(.HasHorizontalScroll) > div {
    overflow: auto;
}

.accordionSummary :global(.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters) {
    width: map-get($marginInPercentage, "per95");
  }

.accordionDetailsStyles > div {
    overflow: hidden;
}

.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.leftPanel {
    display: flex;
    align-items: center;
    width: map-get($marginInPercentage, "per75");
}

.leftPanel > * {
    margin-right: map-get($margins, "px8");
}

.rightPanel {
    display: flex;
    align-items: center;
    margin-left: map-get($marginInPercentage, "per3o5");
}

.valueData {
    display: block;
    width: map-get($marginInPercentage, "per100");
}

.configValueData {
    display: block;
    width: map-get($marginInPercentage, "per250");
}

.labelData {
    display: flex;
    white-space: nowrap;
}

.labelStyles {
    color: map-get($buttons, "chipLabel");
    font-weight: map-get($weight, "bolder");
    margin-right: map-get($marginInPixel, "px12");
}

  
.icon {
    height: map-get($marginInPixel, "px14");
    width: map-get($marginInPixel, "px16");
    margin-right: map-get($marginInPixel, "px12");
}

@media (min-width: 1600px) {
    .leftPanel {
        width: map-get($marginInPercentage, "per80");
    }
    .configValueData {
        width: map-get($marginInPercentage, "per150");
    }    
}

@media (min-width: 1800px) {
    .leftPanel {
      width: map-get($marginInPercentage, "per80");
    }
    .configValueData {
        width: map-get($marginInPercentage, "per100");
    }    
}
