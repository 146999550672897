@import "../../utils/styles/fonts.scss";
@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";

.children {
  display: block;
}
.popupButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: map-get($marginInPixel, "px10");
}

.popupButton > button {
  width: map-get($marginInPercentage, "per25");
  margin-left: map-get($marginInPercentage, "per025");
}

.popupContent > button {
  position: absolute;
}

.iconFill {
  right: map-get($marginInPixel, "px7");
  top: map-get($marginInPixel, "px7");
}
.overlay {
  background: $cardLabel;
  bottom: 0;
  left: 0;
  opacity: 0.72;
  position: fixed;
  right: 0;
  top: 0;
}
.popupContent {
  background: $white;
  border-radius: map-get($marginInPixel, "px1");
  left: map-get($marginInPercentage, "per50");
  padding: map-get($marginInPixel, "px16") map-get($marginInPixel, "px24")
    map-get($marginInPixel, "px16") map-get($marginInPixel, "px24");
  position: absolute;
  top: map-get($marginInPercentage, "per50");
  transform: translate(-50%, -50%);
  min-width: map-get($marginInPercentage, "per28");
  // height: map-get($marginInPixel, "px450" );
}
.popupHeader {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
.popupHeading {
  line-height: map-get($marginInPixel, "px28");
  margin-bottom: map-get($marginInPixel, "px22");
  margin-top: map-get($marginInPixel, "px12");
}
