@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.cardContainer {
  width: map-get($marginInPercentage, "per33");
  height: map-get($marginInPercentage, "per120");
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  background-color: $white;
  padding: map-get($marginInPixel, "px15") map-get($marginInPercentage, "per2");
  border-radius: map-get($marginInPixel, "px8");
}

.title {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px24");
  line-height: map-get($marginInPixel, "px24");
  margin-bottom: map-get($marginInPixel, "px12");
  text-align: center;
  color: $textLabel;
}

.chart {
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px54");
  text-align: center;
}

.customTooltipStyle {
  background-color: $white;
  border: map-get($marginInPixel, "px1") solid $expandedRowBorder;
  height: map-get($marginInPixel, "px32");
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px12");
  box-shadow: 0 map-get($marginInPixel, "px1") map-get($marginInPixel, "px5") 0 #1e1f2052;
  z-index: 1000;
  border-radius: map-get($marginInPixel, "px5");
  transform: translate(-50%, map-get($marginInPixel, "px40"));
}

.customTooltipStyle::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: map-get($marginInPixel, "px10") solid transparent;
  border-right: map-get($marginInPixel, "px10") solid transparent;
  border-bottom: map-get($marginInPixel, "px10") solid #80878e;
  top: -10px;
  left: map-get($marginInPercentage, "per50");
  transform: translateX(-50%);
}

.customTooltipStyle p {
  position: relative;
  margin: 0;
  font-size: map-get($marginInPixel, "px14");
}

.MuiTooltip-tooltipPlacementBottom {
  position: absolute;
  left: -10px !important;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: map-get($marginInPercentage, "per60");
  gap: map-get($marginInPixel, "px10");
  line-height: map-get($marginInPixel, "px20");
  color: $pureBlack;
  font-size: map-get($marginInPixel, "px14");
}

.labelContainer {
  display: inline-block;
}

.chartSection{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: map-get($marginInPercentage, "per40");
  margin-top: -10px;
}

.badge {
  width: map-get($marginInPixel, "px8");
  height: map-get($marginInPixel, "px8");
  display: inline-flex;
  border-radius: map-get($marginInPixel, "px180");
  margin-right: map-get($marginInPixel, "px8");
}

.pieChartTooltip {
  height: map-get($marginInPixel, "px32");
  background-color: $tooltip;
  box-shadow: 0 map-get($marginInPixel, "px1") map-get($marginInPixel, "px5") 0 $tooltipBoxShadow;
  width: max-content;
  padding: map-get($marginInPixel, "px6") map-get($marginInPixel, "px12");
  font-size: map-get($size, "px14");
  color: $textValue;
  border: map-get($marginInPixel, "px1") solid $expandedRowBorder;
  border-radius: map-get($marginInPixel, "px4");
}

.pieChartTooltip .pieChartTooltipLabel::before {
  content: '';
  display: block;  
  position: absolute;
  left: map-get($marginInPercentage, "per44o1");
  bottom: map-get($marginInPercentage, "per95o9");
  width: 0;
  height: 0;
  border: map-get($marginInPixel, "px8") solid transparent;
  border-bottom-color: $expandedRowBorderLite;
}

.pieChartTooltip .pieChartTooltipLabel::after {
  content: '';
  display: block;  
  position: absolute;
  left: map-get($marginInPercentage, "per45");
  bottom:map-get($marginInPercentage, "per95o8");
  width: 0;
  height: 0;
  border: map-get($marginInPixel, "px7") solid transparent;
  border-bottom-color: $tooltip;
}
