@import "../../utils/styles/margins.scss";
@import "../../utils/styles/colors.scss";
@import "../../utils/styles/fonts.scss";

.card:hover{
  background-color: $cardHover
}

.card:hover :global(.emptyBar){
  background-color: inherit
}

.card:focus{
  background-color: map-get($buttons, "contentPrimaryhover");
}

.card {
  align-items: center;
  background: $white;
  border: map-get($marginInPixel, "px1") solid $borderGrey;
  border-radius: map-get($marginInPixel, "px8");
  cursor: pointer;
  height: map-get($marginInPixel, "px192");
  width: map-get($marginInPercentage, "per32o43");
  padding: map-get($marginInPixel, "px16");
}

.siteIcon {
 margin-right: map-get($marginInPixel, "px8");
 margin-top: 0;
}

.cardDetail {
  align-items: center;
  display: flex;
  margin-top: map-get($marginInPixel, "px16");
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px24");
}

.hospitalData {
  align-items: center;
  color: $cardLabel;
  display: flex;
  width: map-get($marginInPercentage, "per100");
  height: map-get($marginInPixel, "px32");
}

.idData {
  min-width: map-get($marginInPercentage, "per32");
}

.cityData {
  margin-left: map-get($marginInPercentage, "per6o8");
  min-width: map-get($marginInPixel, "px223");
}

.distributionData {
  display: flex;
  justify-content: space-between;
  margin-top: map-get($marginInPixel, "px16");
  width: map-get($marginInPercentage, "per100");
  flex-wrap: wrap;
  height: 72px;
}

.distributionData > div:nth-last-child(1) {
  margin-top: map-get($marginInPixel, "px16");
}

.distributionData > div:nth-last-child(2) {
  margin-top: map-get($marginInPixel, "px16");
}

.distributionCards {
  width: map-get($marginInPercentage, "per43o3");
}

.removeLinkStyles {
  text-decoration: none;
  color: inherit;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
